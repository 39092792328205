angular.module("componentes").controller("FileUploadCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"fileService",
	function ($scope, $rootScope, $q, fileService) {
		"use strict";

		$scope.myfiles = { files: [] };
		$scope.filesDTO = [];
		$scope.fileOptions = {
			browseIconCls: "fileUploadIcon md-accent",
			removeIconCls: "fileRemoveIcon",
			captionIconCls: "fileCaptionIcon",
			unknowIconCls: "fileUnknownIcon"
		};
		$scope.fileApi = {};

		this.$onInit = function () {
			$scope.ctrl.options.getFilesFromPathList = $scope.getFilesFromPathList;

			$scope.ctrl.options.getFileList = function (afterUpload) {
				if ($scope.ctrl.options.get) {
					$scope.ctrl.options.get().then(function (response) {
						if (Array.isArray(response)) {
							receiveBytesFromList(response);
						} else {
							$scope.receiveS3FilePathsFromServer(response);
							$scope.receiveBytesFromServer(response);
						}
					});
				}
			};

			$scope.ctrl.options.getNewFileList = function (afterUpload) {
				return $scope.getNewFiles();
			};

			$scope.ctrl.options.removeAll = function () {
				$scope.fileOptions.api.removeAll();
			};

			$scope.ctrl.options.addFile = function (file, fileName, fileExtension) {
				$scope.fileOptions.api.addFile(file, fileName, fileExtension);
			};

			$scope.ctrl.options.hasChangedFiles = function () {
				return $scope.getRemovedFiles().length > 0 || $scope.getNewFiles().length > 0;
			};

			$scope.ctrl.options.updateFiles = function () {
				var newFiles = $scope.getNewFiles();
				if ($scope.invalidFilesSize(newFiles) && $scope.ctrl.options.fileService !== "mixed") {
					var deferred = $q.defer();
					deferred.reject({ d: '{"success": "false", "errorCode": "FILE_GREATER_THAN_MAX_SIZE"}' });
					return deferred.promise;
				}

				var deletedIds = $scope.getRemovedFilesIdsString();
				var removedFiles = $scope.getRemovedFiles();

				var promise = null;
				if ($scope.ctrl.options instanceof Cmp.constructors.FileOptions) {
					promise = $scope.ctrl.options.update(newFiles.concat(removedFiles));
				} else if (removedFiles.length == 1) {
					promise = $scope.ctrl.options.update(newFiles, deletedIds, removedFiles[0].Name);
				} else {
					promise = $scope.ctrl.options.update(newFiles, deletedIds);
				}

				promise.then(success, fail);

				return promise;
			};

			$scope.ctrl.options.getFileList();

			$scope.ctrl.options.addFiles = function (pathList) {
				if (pathList && Array.isArray(pathList) && pathList.length > 0) {
					$scope.getFilesFromPathList(pathList, true);
				}
			};
		};

		$scope.receiveBytesFromServer = function (response) {
			var data;
			if (!response.data.d) {
				data = response.data;
			} else {
				data = JSON.parse(response.data.d);
			}

			$scope.fileOptions.api.removeAll();
			$scope.filesDTO = [];
			if (data.success && data.content) {
				var list = data.content.List || data.content.list;
				if (list) {
					var filesDTO = list;
					filesDTO.forEach(function (fileDTO) {
						if (fileDTO && !fileDTO.S3Key) {
							var file = Vgr.util.fileDtoToFile(fileDTO);
							$scope.fileOptions.api.addFile(file, fileDTO.Name, fileDTO.Extension);
							$scope.filesDTO.push(fileDTO);
						}
					});
				} else {
					var fileDTO = data.content;
					var file = Vgr.util.fileDtoToFile(fileDTO);
					$scope.fileOptions.api.addFile(file, fileDTO.Name, fileDTO.Extension);
					$scope.filesDTO = $scope.filesDTO.concat([fileDTO]);
				}
			}
		};

		$scope.receiveS3FilePathsFromServer = function (response) {
			if (response.data.d) {
				// means its from old server, we dont have s3 on it.
				return;
			}

			var data = response.data;
			if (JSON.parse(data.success) && data.content) {
				var list = data.content.List || data.content.list;
				if (list) {
					$scope.getFilesFromPathList(list);
				}
			}
		};

		$scope.getFilesFromPathList = function (pathList) {
			var promises = [];
			var promisesPaths = [];
			if (Array.isArray(pathList)) {
				pathList.forEach(function (path) {
					if (path instanceof Object) {
						var key = path.S3Key || path.path;
						if (key) {
							promises.push(fileService.getSignedURL(key, "get"));
							promisesPaths.push(path);
						}
					} else if (typeof path === "string") {
						promises.push(fileService.getSignedURL(path, "get"));
						promisesPaths.push(path);
					}
				});
			} else {
				var path = pathList;
				pathList = [path];
				if (typeof path === "string") {
					promises.push(fileService.getSignedURL(path, "get"));
					promisesPaths.push(path);
				}
			}

			var allPromise = $q.all(promises);

			allPromise.then(function (responses) {
				responses.forEach(function (response) {
					var file = {
						url: response.data[0].fileLink,
						path:
							promisesPaths[responses.indexOf(response)] instanceof Object
								? promisesPaths[responses.indexOf(response)].path || promisesPaths[responses.indexOf(response)].S3Key
								: promisesPaths[responses.indexOf(response)]
					};
					$scope.fileOptions.api.addFile(file, "", "");
				});
			});
		};

		function receiveBytesFromList(list) {
			$scope.filesDTO = [];
			if (list) {
				var filesDTO = list;
				filesDTO.forEach(function (fileDTO) {
					if (fileDTO && !fileDTO.S3Key) {
						var file = Vgr.util.fileDtoToFile(fileDTO);
						$scope.fileOptions.api.addFile(file, fileDTO.Name, fileDTO.Extension);
						$scope.filesDTO.push(fileDTO);
					}
				});
			}
		}

		$scope.getNewFiles = function () {
			var newFiles = [];

			if ($scope.myfiles.files.length > 0) {
				if ($scope.filesDTO) {
					newFiles = $scope.myfiles.files.filter(function (file) {
						return !$scope.filesDTO.find(function (fileDTO) {
							return fileDTO.HashID ? fileDTO.HashID == file.lfFile.HashID : fileDTO.ID == file.lfFile.ID;
						});
					});
				}
			}

			return newFiles;
		};

		$scope.invalidFilesSize = function (files) {
			for (var i = 0; i < files.length; i++) {
				if (
					(files[i].lfFile.size > Vgr.constants.MAX_FILE_SIZE && Vgr.constants.MAX_FILE_SIZE !== 0) ||
					($scope.ctrl.options.maxUploadFileSize && files[i].lfFile.size > $scope.ctrl.options.maxUploadFileSize)
				) {
					return true;
				}
			}
			return false;
		};

		$scope.getRemovedFilesIdsString = function () {
			var removedFiles = $scope.getRemovedFiles();
			var removedIds = "";
			if (removedFiles.length > 0) {
				var Ids = [];
				var HashIds = [];
				removedFiles.forEach(function (element, index, array) {
					Ids[index] = element.ID;
					if (element.HashID) {
						HashIds.push(element.HashID);
					}
				});
				if (HashIds.length > 0) {
					removedIds = HashIds.join(", ");
				} else {
					removedIds = Ids.join(", ");
				}
			}
			return removedIds;
		};

		$scope.getRemovedFiles = function () {
			var removedFiles = [];

			if ($scope.filesDTO) {
				removedFiles = $scope.filesDTO.filter(function (fileDTO) {
					return !$scope.myfiles.files.find(function (file) {
						return fileDTO.HashID ? fileDTO.HashID == file.lfFile.HashID : fileDTO.ID == file.lfFile.ID;
					});
				});
			}

			removedFiles.forEach(function (file) {
				file.IsDeleted = true;
			});

			return removedFiles;
		};

		var success = function (response) {
			if (!$scope.ctrl.options.fileService || $scope.ctrl.options.fileService !== "mixed") {
				$scope.ctrl.options.getFileList();
			}
		};

		var fail = function (response) {
			$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SAVE_FILE_ERROR);
		};

		$scope.getPreview = function () {
			return $scope.ctrl.removePreview ? null : "";
		};

		$scope.$watch("ctrl.options.pathList", function (newVal) {
			if (newVal) {
				$scope.getFilesFromPathList(newVal, true);
			}
		});
	}
]);
