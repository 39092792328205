angular.module("componentes").directive("toolbarButtons", function () {
	"use strict";
	return {
		scope: true,
		controller: "ToolbarButtonsCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/toolbarButtons/toolbarButtons_template.html",
		bindToController: {
			options: "="
		}
	};
});
