angular.module("componentes").directive("gridSimpleV2", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: true,
			controller: "GridSimpleV2Ctrl",
			controllerAs: "ctrl",
			templateUrl: "componentes/angular-material/custom/gridSimple/V2/gridSimpleV2_template.html",
			bindToController: {
				selectId: "@",
				orderBy: "@",
				list: "=",
				columns: "=",
				title: "@",
				btnAddClick: "=",
				defaultActions: "=",
				actionButtons: "=",
				loading: "=?",
				noValuesLabel: "@?"
			},
			link: function (scope, element, attr) {}
		};
	}
]);
