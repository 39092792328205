angular.module("componentes").directive("numberFieldV2", function () {
	"use strict";

	return {
		scope: true,
		controller: "NumberFieldV2Ctrl",
		controllerAs: "ctrl",
		//transclude: true,
		replace: true,
		templateUrl: "componentes/angular-material/custom/fields/numberField/numberFieldV2_template.html",
		bindToController: {
			label: "@",
			name: "@",
			isDisabled: "=",
			isRequired: "=",
			type: "@",
			decimals: "=?",
			field: "=",
			error: "=",
			mask: "@?",
			min: "=?",
			maxlength: "@?",
			max: "=?",
			onBlur: "&?",
			changed: "=?",
			rightField: "@",
			inputContainerClass: "@"
		},
		link: function (scope, element, attrs) {
			if (scope && !scope.ctrl.min) {
				scope.ctrl.min = 0;
			}
		}
	};
});
