angular.module("componentes").controller("ReportViewCtrl", [
	"$scope",
	function ($scope) {
		"use strict";
		$scope.file = {};

		$scope.$watch("ctrl.options.promise", function (newVal) {
			if (newVal) {
				newVal.then(function (response) {
					const data = response.data.d ? JSON.parse(response.data.d) : response.data;
					if (data.success) {
						if (data.content.Extension && data.content.Extension.toUpperCase().endsWith("PDF")) {
							$scope.file = Vgr.util.fileDtoToFile(data.content);
						} else {
							const fileDTO = Vgr.util.fileDtoToFile(data.content);
							saveAs(fileDTO, data.content.Name);
						}
					}
					return data.success;
				});
			}
		});
	}
]);
