angular.module("componentes").directive("gridInternalToolbar", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: false,
			templateUrl: "componentes/angular-material/custom/grid/gridInternalToolbar_template.html",
			link: function (scope, element, attr) {
				element.on("$destroy", function () {
					$rootScope.$broadcast(Vgr.constants.evtDestroyGrid);
				});
			}
		};
	}
]);
