angular.module("componentes").component("multiSelectV2", {
	controller: "MultiSelectV2Ctrl",
	templateUrl: "componentes/angular-material/custom/multiSelectV2/multiSelectV2_template.html",
	bindings: {
		label: "@",
		searchLabel: "@?",
		key: "@?",
		displayProperty: "@?",
		customClasses: "@?",
		showSelectAll: "<?",
		isRequired: "<?",
		isDisabled: "<?",

		items: "<",

		setter: "<?",
		callback: "<",

		clear: "<?"
	}
});
