angular.module("componentes").directive("menuToggle", function () {
	"use strict";
	return {
		scope: true,
		replace: false,
		templateUrl: "componentes/angular-material/custom/sideNav/menuToggle_template.html",
		controller: "menuToggleCtrl",
		transclude: true,
		controllerAs: "ctrl",
		bindToController: {
			item: "="
		}
	};
});
