angular.module("componentes").directive("menuLink", function () {
	"use strict";

	return {
		scope: true,
		templateUrl: "componentes/angular-material/custom/sideNav/menuLink_template.html",
		controller: "menuLinkCtrl",
		controllerAs: "ctrl",
		replace: true,
		bindToController: {
			item: "="
		}
	};
});
