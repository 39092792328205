angular.module("componentes").directive("breadcrumb", function () {
	"use strict";

	return {
		scope: true,
		controller: "BreadcrumbCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/breadcrumb/breadcrumb_template.html",
		bindToController: {
			levels: "=",
			click: "="
		}
	};
});
