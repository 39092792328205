angular.module("componentes").controller("menuToggleCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	function ($scope, $rootScope, accountService) {
		"use strict";
		this.$onInit = function () {
			$scope.showDisabled = $scope.ctrl.item.featureAccess == Vgr.enumerations.userAccess.Disabled;
		};
	}
]);
