angular.module("componentes").controller("GridSimpleCtrl", [
	"$scope",
	"$timeout",
	"$q",
	function ($scope, $timeout, $q) {
		"use strict";
		$scope.getDescendantProp = function (obj, propertyPath) {
			if ($scope.ctrl.disablePropertiesAsObjects) {
				return obj[propertyPath];
			} else {
				return Vgr.util.getDescendantProp(obj, propertyPath);
			}
		};
		$scope.canOpenMenu = true;

		$scope.onAddClick = function () {
			if ($scope.ctrl.btnAddClick) {
				var addResult = $scope.ctrl.btnAddClick();
				if (addResult) {
					addResult.then(function (item) {
						$scope.ctrl.list.push(item);
					});
				}
			}
		};

		$scope.onRefreshClick = function () {
			if ($scope.ctrl.btnRefreshClick) {
				var refreshResult = $scope.ctrl.btnRefreshClick();
				if (refreshResult) {
					refreshResult.then(function (list) {
						$scope.ctrl.list = list;
					});
				}
			}
		};

		$scope.click = function (cbClick, row) {
			$scope.canOpenMenu = false;
			var promiseClick = cbClick(row);
			if (Vgr.util.isPromise(promiseClick)) {
				promiseClick.then(
					function () {
						setCanOpenMenuWithTimeout(true);
					},
					function () {
						setCanOpenMenuWithTimeout(true);
					}
				);
			} else {
				$scope.canOpenMenu = true;
			}
		};

		$scope.onEditItemClick = function (item) {
			if ($scope.ctrl.btnEditClick) {
				$scope.ctrl.btnEditClick(item);
			}
		};

		$scope.onDeleteSingleItemClick = function (item) {
			$scope.canOpenMenu = false;
			executeAndSetCanOpenMenuToTrue(function () {
				var index = getIndexFromItemBySelectId(item);
				if (index >= 0) {
					$scope.ctrl.list.splice(index, 1);
				}
			});
		};

		$scope.onDeleteSelectedClick = function () {
			$scope.canOpenMenu = false;
			executeAndSetCanOpenMenuToTrue(function () {
				var indexes = getIndexesFromArrayBySelectId($scope.ctrl.selectedRows);
				if (indexes.length > 0) {
					var i = indexes.length;
					while (i--) {
						$scope.ctrl.list.splice(indexes[i], 1);
					}
				}
				$scope.ctrl.selectedRows = [];
			});
		};

		function executeAndSetCanOpenMenuToTrue(cbFunction) {
			cbFunction();
			setCanOpenMenuWithTimeout(true);
		}

		function setCanOpenMenuWithTimeout(canOpenMenu) {
			$timeout(function () {
				$scope.canOpenMenu = canOpenMenu;
			}, 500);
		}

		function getIndexFromItemBySelectId(item) {
			for (var i = 0; i < $scope.ctrl.list.length; ++i) {
				if ($scope.ctrl.list[i][$scope.ctrl.selectId] == item[$scope.ctrl.selectId]) {
					return i;
				}
			}
			return -1;
		}

		function getIndexesFromArrayBySelectId(array) {
			var indexes = [];
			for (var i = 0; i < $scope.ctrl.list.length; ++i) {
				for (var j = 0; j < array.length; ++j) {
					if ($scope.ctrl.list[i][$scope.ctrl.selectId] == array[j][$scope.ctrl.selectId]) {
						indexes.push(i);
					}
				}
			}
			return indexes;
		}

		$scope.hasNoActionButtons = function () {
			return !$scope.ctrl.actionButtons || $scope.ctrl.actionButtons.length == 0;
		};

		$scope.isToShowEditButton = function () {
			return $scope.ctrl.defaultActions && $scope.ctrl.defaultActions.indexOf("edit") > -1;
		};

		$scope.isToShowDeleteComponents = function () {
			return $scope.ctrl.defaultActions && $scope.ctrl.defaultActions.indexOf("delete") > -1;
		};

		$scope.isToShowCheckboxColumn = function () {
			return $scope.isToShowDeleteComponents() || $scope.ctrl.showCheckboxColumn;
		};

		$scope.isToShowAddButton = function () {
			return $scope.ctrl.defaultActions && $scope.ctrl.defaultActions.indexOf("add") > -1;
		};

		$scope.isToShowRefreshButton = function () {
			return $scope.ctrl.defaultActions && $scope.ctrl.defaultActions.indexOf("refresh") > -1;
		};

		$scope.isToShowGridActionButtonsComponent = function () {
			return (
				$scope.isToShowCheckboxColumn() ||
				$scope.isToShowEditButton() ||
				($scope.ctrl.actionButtons && $scope.ctrl.actionButtons.length > 0)
			);
		};

		$scope.getClass = function (column, item) {
			if (column.getClass) {
				return column.getClass(item);
			} else {
				return "";
			}
		};

		$scope.hasPermission = function (item) {
			if (!$scope.ctrl.hasPermission) {
				return true;
			}

			return $scope.ctrl.hasPermission(item);
		};

		function setRows(rowsIds) {
			$scope.ctrl.list.forEach(function (item) {
				var foundRow = rowsIds.find(function (rowId) {
					return rowId == item[$scope.ctrl.selectId];
				});

				if (foundRow != null) {
					$scope.ctrl.selectedRows.push(item);
				}
			});
		}

		$scope.$watch("ctrl.setterRowsIds", function (newValue, oldValue) {
			if (newValue && newValue != oldValue) {
				setRows(newValue);
			}
		});

		$scope.$watch("ctrl.list.length", function (newValue, oldValue) {
			if (newValue && newValue != oldValue && $scope.ctrl.setterRowsIds) {
				setRows($scope.ctrl.setterRowsIds);
			}
		});

		this.$onInit = function () {
			if (!$scope.ctrl.selectedRows) {
				$scope.ctrl.selectedRows = [];
			}
		};
	}
]);
