angular.module("componentes").directive("gridSimpleActionButtons", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: false,
			templateUrl: "componentes/angular-material/custom/gridSimple/gridSimpleActionButtons_template.html"
		};
	}
]);
