angular.module("componentes").directive("fileUpload", function () {
	"use strict";

	return {
		scope: {},
		controller: "FileUploadCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/fileUpload/fileUpload_template.html",
		bindToController: {
			noDelete: "=?",
			multiple: "@?",
			options: "=",
			allowedExtensions: "@?",
			removePreview: "@?",
			isDisabled: "=?"
		}
	};
});

Cmp.constructors.S3Files = function S3Files() {
	this.$q = Vgr.util.getService("$q");
	this.fileS3Service = Vgr.util.getService("fileService");

	this.getFilesS3Keys = function getFilesS3Keys(files, s3FolderName) {
		var deferred = this.$q.defer();

		var newFiles = files.filter(function (file) {
			return !file.ID && !file.IsDeleted && !file.S3Key;
		});

		this.fileS3Service.uploadAll(newFiles, s3FolderName).then(function (response) {
			for (var index = 0; index < response.length; index++) {
				newFiles[index].S3Key = response[index];
			}

			deferred.resolve(files);
		}, deferred.reject);

		return deferred.promise;
	};
};

Cmp.constructors.FileOptions = function FileOptions(saveNewFiles, getFiles, s3FolderName) {
	Cmp.constructors.S3Files.call(this);

	this.fileService = "mixed";
	this.update = function (files) {
		var deferred = this.$q.defer();

		this.getFilesS3Keys(files, s3FolderName).then(function (response) {
			saveNewFiles(files).then(deferred.resolve, deferred.reject);
		});

		return deferred.promise;
	};

	this.get = getFiles;
};

Cmp.constructors.FileOptions.prototype = Object.create(Cmp.constructors.S3Files.prototype);
