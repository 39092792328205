angular.module("componentes").directive("selectFieldV2", function () {
	"use strict";

	return {
		scope: true,
		controller: "SelectFieldV2Ctrl",
		controllerAs: "ctrl",
		replace: true,
		templateUrl: "componentes/angular-material/custom/selectField/selectFieldV2_template.html",
		bindToController: {
			label: "@",
			name: "@",
			model: "=",
			valueField: "@",
			displayField: "@",
			isRequired: "=",
			error: "=",
			loadList: "=?",
			loadListItem: "=?",
			list: "=?",
			isDisabled: "@?",
			changed: "=",
			hasEmptyField: "=",
			customClass: "@",
			ignoreAutomaticSort: "@?",
			placeholder: "@"
		}
	};
});
