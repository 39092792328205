angular.module("componentes").directive("textArea", function () {
	"use strict";

	return {
		scope: true,
		controller: "TextAreaCtrl",
		controllerAs: "ctrl",
		//transclude: true,
		replace: true,
		templateUrl: "componentes/angular-material/custom/fields/textArea/textArea_template.html",
		bindToController: {
			label: "@",
			name: "@",
			rows: "@",
			maxLength: "@",
			isDisabled: "=",
			field: "=",
			error: "=?",
			isRequired: "=",
			onChange: "=?"
		}
	};
});
