angular.module("componentes").controller("SelectFieldV2Ctrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		var _list = [];

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.placeholder = $scope.ctrl.label ? $scope.ctrl.label : $scope.ctrl.placeholder;

			Object.defineProperty($scope, "list", {
				get: function () {
					return _list;
				},
				set: function (value) {
					_list = value;
					if (_list && !$scope.ctrl.ignoreAutomaticSort) {
						_list.sort(sortObjects);
					}
				},
				enumerable: true
			});

			$scope.getList();
		};

		function sortObjects(objA, objB) {
			return objA[$scope.ctrl.displayField].toString().localeCompare(objB[$scope.ctrl.displayField].toString());
		}

		$scope.$watch("ctrl.model", function (newValue) {
			if ($scope.ctrl.changed) {
				$scope.ctrl.changed();
			}
		});

		$scope.$watch("ctrl.list", function (newValue) {
			if (!newValue) {
				$scope.list = [];
			} else {
				$scope.list = newValue;
				addEmptyFieldIfNecessary();
			}
		});

		function addEmptyFieldIfNecessary() {
			if (!$scope.ctrl.hasEmptyField || !$scope.list || $scope.list.length == 0) {
				return;
			}

			if (
				($scope.ctrl.valueField && !$scope.list[0][$scope.ctrl.valueField]) ||
				$scope.list[0][$scope.ctrl.displayField] == ""
			) {
				return;
			}

			var newItem = {};
			if ($scope.ctrl.valueField) {
				newItem[$scope.ctrl.valueField] = undefined;
			}
			newItem[$scope.ctrl.displayField] = "";
			$scope.list.unshift(newItem);
		}

		$scope.getList = function () {
			if (!$scope.ctrl.loadList) {
				$scope.list = $scope.ctrl.list;
				addEmptyFieldIfNecessary();
			} else {
				$scope.ctrl.loadList($scope.ctrl.loadListItem).then(function (list) {
					$scope.list = list;
					addEmptyFieldIfNecessary();
				});
			}
		};
	}
]);
