angular.module("componentes").component("chart", {
	controller: "ChartCtrl",
	templateUrl: "componentes/angular-material/custom/chart/chart_template.html",
	bindings: {
		data: "<",
		series: "<",
		labels: "<",
		chartTitle: "@?",
		type: "@",
		customChartOptions: "<",
		yAxisDecimals: "<?",
		datasetOverride: "<?",
		customHeight: "<?"
	}
});
