/**
 * @author Umed Khudoiberdiev <info@zar.tj>
 */
(function (angular, undefined) {
	"use strict";

	var skipDisableClass = "skip-disable";
	var disableAllClass = "disable-all";

	/**
	 * @ngdoc module
	 * @name disableAll
	 * @description
	 * This module represents a disable-all directive which allows you to disable any element in the DOM.
	 */
	angular.module("disableAll", []);

	/**
	 * @ngdoc directive
	 * @name skipDisable
	 * @restrict A
	 * @description
	 * This directive allows you to do not disable the element even if it's under the disableAll directive
	 */
	angular.module("disableAll").directive("skipDisable", skipDisableDirective);

	/**
	 * @ngInject
	 */
	function skipDisableDirective() {
		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				var skipDisable = attrs.skipDisable ? JSON.parse(attrs.skipDisable) : false;
				var disabledElement = attrs.disableElementId ? document.getElementById(attrs.disableElementId) : element[0];

				var getAllChildren = function (domElement) {
					var children = [];
					if (domElement && domElement.childNodes && domElement.childNodes.length > 0) {
						for (var i = 0; i < domElement.childNodes.length; i++) {
							var child = domElement.childNodes[i];
							children.push(child);
							children = children.concat(getAllChildren(child));
						}
					}
					return children;
				};

				// Observe change on skipDisable interpolation
				attrs.$observe("skipDisable", function (newSkipDisable) {
					if (newSkipDisable === "false" || newSkipDisable === "true") {
						toggleSkipDisable(disabledElement, JSON.parse(newSkipDisable));
					}
				});

				// Watch on DOM changement
				scope.$watch(
					function () {
						return getAllChildren(element[0]).length;
					},
					function () {
						if (skipDisable) {
							$(disabledElement).addClass(skipDisableClass);
						}
					}
				);
			}
		};
	}

	var toggleSkipDisable = function (element, isSkipDisable) {
		var hasParentWithDisableAll = $(element).closest(".disable-all").length > 0;
		if (hasParentWithDisableAll) {
			if (isSkipDisable) {
				$(element).addClass(skipDisableClass);
				enableAll(element);
			} else {
				$(element).removeClass(skipDisableClass);
				disableAll(element);
			}
		}
	};

	/**
	 * @ngdoc directive
	 * @name disableAll
	 * @restrict A
	 * @description
	 * This directive allows you to disable any element in the DOM. Directive turns off all clicks, disables
	 * inputs, buttons and textareas in the given element scope.
	 */
	angular.module("disableAll").directive("disableAll", disableAllDirective);

	/**
	 * @ngInject
	 */
	function disableAllDirective() {
		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				var disabledElement = attrs.disableElementId ? document.getElementById(attrs.disableElementId) : element[0];

				var getAllChildren = function (domElement) {
					var children = [];
					if (domElement && domElement.childNodes && domElement.childNodes.length > 0) {
						for (var i = 0; i < domElement.childNodes.length; i++) {
							var child = domElement.childNodes[i];
							children.push(child);
							children = children.concat(getAllChildren(child));
						}
					}
					return children;
				};

				var isDisabled = false;
				// Watch on DisableAll attribute value
				scope.$watch(attrs.disableAll, function (_isDisabled) {
					isDisabled = _isDisabled;
					toggleDisableAll(disabledElement, _isDisabled);
				});

				// Watch on DOM changement
				scope.$watch(
					function () {
						return getAllChildren(element[0]).length;
					},
					function (newValue, oldValue) {
						if (newValue !== oldValue) {
							toggleDisableAll(disabledElement, isDisabled);
						}
					}
				);

				scope.$on("$destroy", function () {
					enableAll(disabledElement);
				});
			}
		};
	}

	var toggleDisableAll = function (element, isDisabled) {
		if (typeof isDisabled === "undefined") {
			return;
		}
		if (isDisabled) {
			angular.element(element).addClass(disableAllClass);
			disableAll(element);
		} else {
			angular.element(element).removeClass(disableAllClass);
			enableAll(element);
		}
	};

	/**
	 * Disables everything in the given element.
	 *
	 * @param {HTMLElement} element
	 */
	var disableAll = function (element) {
		disableElements(element.getElementsByTagName("input"));
		disableElements(element.getElementsByTagName("button"));
		disableElements(element.getElementsByTagName("textarea"));
		disableElements(element.getElementsByTagName("select"));
		disableElements(element.getElementsByTagName("md-input-container"));
		element.addEventListener("click", preventDefault, true);
	};

	/**
	 * Enables everything in the given element.
	 *
	 * @param {HTMLElement} element
	 */
	var enableAll = function (element) {
		enableElements(element.getElementsByTagName("input"));
		enableElements(element.getElementsByTagName("button"));
		enableElements(element.getElementsByTagName("textarea"));
		enableElements(element.getElementsByTagName("select"));
		enableElements(element.getElementsByTagName("md-input-container"));
		enableElements([element]);
		element.removeEventListener("click", preventDefault, true);
	};

	var shouldClick = function (element) {
		var canClick =
			$(element).closest(
				"md-tab-item,table,.back-button,md-table-pagination,.grid-filter-button,.search-bar,.file-download,.skip-disable"
			).length > 0;
		return canClick;
	};

	/**
	 * Callback used to prevent user clicks.
	 *
	 * @param {Event} event
	 * @returns {boolean}
	 */
	var preventDefault = function (event) {
		for (var i = 0; i < event.target.attributes.length; i++) {
			if (shouldClick(event.target)) {
				return true;
			}
		}
		event.stopPropagation();
		event.preventDefault();
		return false;
	};

	/**
	 * Disables given elements.
	 *
	 * @param {Array.<HTMLElement>|NodeList} elements List of dom elements that must be disabled
	 */
	var disableElements = function (elements) {
		var len = elements.length;
		for (var i = 0; i < len; i++) {
			var shouldDisable = true;
			for (var j = 0; j < elements[i].attributes.length; j++) {
				if (shouldClick(elements[i])) {
					shouldDisable = false;
					continue;
				}
			}

			if (shouldDisable && !!elements[i].disabled === false) {
				elements[i].style.color = "gray";
				elements[i].setAttribute("ng-disable", true);
				elements[i].disabled = true;
				elements[i].disabledIf = true;
			}
		}
	};

	/**
	 * Enables given elements.
	 *
	 * @param {Array.<HTMLElement>|NodeList} elements List of dom elements that must be enabled
	 */
	var enableElements = function (elements) {
		var len = elements.length;
		for (var i = 0; i < len; i++) {
			var isElementDisabled =
				(elements[i].disabled === true && elements[i].disabledIf === true) ||
				elements[i].getAttribute("ng-disable") === "true";
			if (isElementDisabled) {
				elements[i].style.color = "";
				elements[i].setAttribute("ng-disable", false);
				elements[i].disabled = false;
				elements[i].disabledIf = null;
			}
		}
	};
})(angular);
