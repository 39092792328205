function onFileChanged() {
	return {
		restrict: "A",
		link: function (scope, element, attrs) {
			var onChangeHandler = scope.$eval(attrs.onFileChanged);
			element.bind("change", onChangeHandler);
		}
	};
}

function enterSubmit() {
	return {
		restrict: "A",
		link: function (scope, elem, attrs) {
			elem.bind("keydown", function (event) {
				var code = event.keyCode || event.which;
				var isDisabled = $(elem).is(":disabled");
				if (code === 13 && !isDisabled) {
					if (!event.shiftKey) {
						event.preventDefault();
						scope.$apply(attrs.enterSubmit);
					}
				}
			});
		}
	};
}

function maxValue() {
	return {
		scope: { maxValue: "@" },
		link: function (scope, element, attributes, $window) {
			element.keyup(function () {
				var val = parseFloat($(this).val());
				if (!isNullOrEmpty(val) && val > parseFloat(element.attr("max-value"))) {
					$(this).val(element.attr("max-value"));
				}
			});
		}
	};
}

function ngHtmlCompile($compile) {
	return {
		restrict: "A",
		link: function (scope, element, attrs) {
			scope.$watch(attrs.ngHtmlCompile, function (newValue, oldValue) {
				element.html(newValue);
				$compile(element.contents())(scope);
			});
		}
	};
}

function errorMessage() {
	return {
		restrict: "E",
		compile: function (element, attrs) {
			var htmlText =
				'<div class="alert alert-danger alert-dismissible" role="alert" ng-show="show' +
				attrs.messageVariable.capsFirstLetter() +
				'">' +
				'<button type="button" class="close" aria-label="Close" ng-click="close' +
				attrs.messageVariable.capsFirstLetter() +
				'()">' +
				'<span aria-hidden="true">&times;</span>' +
				"</button>" +
				"{{ " +
				attrs.messageVariable +
				" }}" +
				"</div>";

			element.replaceWith(htmlText);
		}
	};
}

function maxHeight() {
	return {
		restrict: "A",
		link: function (scope, element, attributes, $window) {
			var value = attributes.maxHeight;
			element.css("max-height", value);
		}
	};
}

function popover($templateCache, $compile) {
	return {
		restrict: "A",
		scope: {
			template: "@"
		},
		link: function (scope, el, attrs) {
			var template = $templateCache.get(scope.template);
			$(el).popover({
				trigger: "click",
				html: true,
				content: $compile(template)(scope),
				placement: attrs.popoverPlacement
			});
		}
	};
}

function ngPlaceholder() {
	return {
		restrict: "A",
		scope: {
			placeholder: "=ngPlaceholder"
		},
		link: function (scope, elem, attr) {
			scope.$watch("placeholder", function () {
				elem[0].placeholder = scope.placeholder;
			});
		}
	};
}

function pdf() {
	return {
		restrict: "A",
		link: function (scope, element, attrs) {
			var url = scope.$eval(attrs.src);
			var height = attrs.pdfHeight;
			element.replaceWith(
				'<object type="application/pdf" data="' + url + '" width="100%" height="' + height + 'px"></object>'
			);
		}
	};
}

function stopPropagation() {
	return {
		restrict: "A",
		link: function (scope, element, attrs) {
			$(element).on({
				click: function (e) {
					e.stopPropagation();
				}
			});
		}
	};
}

/**
 *
 * Pass all functions into module
 */
angular
	.module("common")
	.directive("onFileChanged", onFileChanged)
	.directive("enterSubmit", enterSubmit)
	.directive("maxValue", maxValue)
	.directive("ngHtmlCompile", ngHtmlCompile)
	.directive("errorMessage", errorMessage)
	.directive("maxHeight", maxHeight)
	.directive("popover", popover)
	.directive("ngPlaceholder", ngPlaceholder)
	.directive("pdf", pdf)
	.directive("stopPropagation", stopPropagation);
