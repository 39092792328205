angular.module("componentes").directive("vgrContainerToolbar", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: false,
			templateUrl: "componentes/angular-material/custom/vgrContainer/vgrContainerToolbar_template.html"
		};
	}
]);
