angular.module("componentes").controller("ChartCtrl", [
	"$scope",
	"$rootScope",
	"clientService",
	function ($scope, $rootScope, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		var yAxisDecimals;

		$scope.readyToLoadChart = false;
		this.$onInit = function () {
			yAxisDecimals = $scope.$ctrl.yAxisDecimals ? $scope.$ctrl.yAxisDecimals : clientService.getDecimalPlaces();

			$scope.chartColors = Vgr.constants.chartColors;

			$scope.chartOptions = getDefaultChartOptions();
			if ($scope.$ctrl.customChartOptions) {
				Object.assign($scope.chartOptions, $scope.$ctrl.customChartOptions);
			}
		};

		function getDefaultChartOptions() {
			var defaultOptions = {
				loading: false,
				responsive: true,
				legend: {
					display: true,
					position: "right",
					fullWidth: false
				},
				maintainAspectRatio: true
			};

			if ($scope.$ctrl.type == "line" || $scope.$ctrl.type == "bar" || $scope.$ctrl.type == "mixed") {
				defaultOptions.scales = {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								callback: yAxisFormat
							}
						}
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true
							},
							gridLines: {
								display: false
							}
						}
					]
				};
			}
			return defaultOptions;
		}

		function yAxisFormat(value) {
			return Vgr.util.formatUnitDecimalPlaces(value, yAxisDecimals);
		}
	}
]);
