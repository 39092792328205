angular.module("componentes").directive("infiniteScrollContainer", function () {
	"use strict";
	return {
		scope: {
			infiniteScrollLimit: "="
		},
		link: function (scope, el, attr) {
			if (scope.infiniteScrollLimit) {
				el.bind("scroll", function () {
					if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
						scope.infiniteScrollLimit += 20;
						scope.$apply();
					}
				});
			}
		}
	};
});
