angular.module("componentes").filter("pagination", function () {
	"use strict";

	return Vgr.filters.pagination;
});

angular.module("componentes").filter("filterBy", function () {
	"use strict";

	return Vgr.filters.filterBy;
});
