angular.module("componentes").directive("sideNav", function () {
	"use strict";
	return {
		scope: true,
		controller: "sideNavCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/sideNav/sideNav_template.html",
		bindToController: {}
	};
});
