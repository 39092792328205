angular.module("componentes").controller("menuCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"userAccessService",
	"accountService",
	"engagementScoreService",
	"stockControlPreferenceService",
	"clientStoragePreferenceService",
	function (
		$scope,
		$rootScope,
		$state,
		userAccessService,
		accountService,
		engagementScoreService,
		stockControlPreferenceService,
		clientStoragePreferenceService
	) {
		"use strict";
		$scope.labels = $rootScope.labels;
		var onEventChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			buildMenu();
		});
		var onStateChangeSuccess = $rootScope.$on("$stateChangeSuccess", function (event, state) {
			selectMenuItemFromState(state.name);
		});

		this.$onInit = function () {
			buildMenu();
		};

		this.$onDestroy = function () {
			// disable the listener
			onEventChangeLoggedClient();
			onStateChangeSuccess();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function buildMenu() {
			if (accountService.getCurrentClient()) {
				clientStoragePreferenceService.getStoragePreferences().then(function (storagePreference) {
					const internalTransferTypePreference = storagePreference.internalTransferType;

					stockControlPreferenceService.getStockControlPreferences().then(
						function (stockPreference) {
							const hasControlStock = stockPreference.enableControlStock;
							$scope.menu = new Cmp.constructors.DefaultMenu(
								$scope.labels,
								userAccessService,
								hasControlStock,
								accountService,
								internalTransferTypePreference
							);
							setShowMenuItemRecursively($scope.menu);
						},
						function () {
							$scope.menu = new Cmp.constructors.DefaultMenu(
								$scope.labels,
								userAccessService,
								true,
								accountService,
								internalTransferTypePreference
							);
							setShowMenuItemRecursively($scope.menu);
						}
					);
				});
			}
		}

		function selectMenuItemFromState(stateName) {
			if (!$scope.menu) {
				return;
			}

			var currentItem = $scope.findItemByState($scope.menu, stateName);
			if (currentItem) {
				$scope.onClickMenuItem(currentItem);
			} else {
				$scope.untoggleAllUnrelatedToggledItems($scope.menu, null);
				$scope.unsetAllItemsActive($scope.menu, null);
			}
		}

		$scope.$watch("menu", function (newVal) {
			if (newVal) {
				selectMenuItemFromState($state.current.name);
			}
		});

		$scope.getParent = function (menuItem, refItem) {
			var parent = null;
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				var child = {};
				for (var i = 0; i < menuItem.children.length; ++i) {
					child = menuItem.children[i];
					child.hasChildren = $scope.hasChildren;

					if ($scope.isEqualToItem(child, refItem)) {
						parent = menuItem;
					} else if (child.hasChildren()) {
						parent = $scope.getParent(child, refItem);
					}

					if (parent) {
						return parent;
					}
				}
			}

			return parent;
		};

		$scope.hasChildren = function hasChildren() {
			if (Array.isArray(this.children)) {
				return this.children.length > 0;
			}

			return false;
		};

		$scope.unsetAllItemsActive = function (menuItem, butItem) {
			if (menuItem.children) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					$scope.unsetAllItemsActive(menuItem.children[i], butItem);
				}
			}
			if (!butItem || butItem.text !== menuItem.text) {
				menuItem.active = false;
			}
		};

		$scope.untoggleAllUnrelatedToggledItems = function (menuItem, refItem) {
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					if (menuItem.children[i].children) {
						$scope.untoggleAllUnrelatedToggledItems(menuItem.children[i], refItem);
					}
					if (!$scope.hasChildrenOrEqual(menuItem.children[i], refItem)) {
						menuItem.children[i].toggled = false;
					}
				}
			}
		};

		$scope.toggleAllRelatedToggledItems = function (menuItem, refItem) {
			for (var i = 0; i < menuItem.children.length; ++i) {
				if (menuItem.children[i].children) {
					$scope.toggleAllRelatedToggledItems(menuItem.children[i], refItem);
				}
				if ($scope.hasChildrenOrEqual(menuItem.children[i], refItem)) {
					menuItem.children[i].toggled = true;
				}
			}
		};

		$scope.isEqualToItem = function (menuItem, refItem) {
			return refItem && menuItem.text === refItem.text;
		};

		$scope.hasState = function (menuItem, state) {
			if (!menuItem.states) {
				return false;
			}

			for (var i = 0; i < menuItem.states.length; ++i) {
				if (menuItem.states[i] === state) {
					return true;
				}
			}
			return false;
		};

		$scope.findItemByState = function (menuItem, state) {
			var item = null;
			for (var i = 0; i < menuItem.children.length; ++i) {
				if ($scope.hasState(menuItem.children[i], state)) {
					item = menuItem.children[i];
				} else if (menuItem.children[i].children) {
					item = $scope.findItemByState(menuItem.children[i], state);
				}
				if (item) {
					return item;
				}
			}
			return item;
		};

		$scope.hasChildrenOrEqual = function (menuItem, refItem) {
			var hasChildren = false;
			if ($scope.isEqualToItem(menuItem, refItem)) {
				hasChildren = true;
			} else if (menuItem.children) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					if ($scope.isEqualToItem(menuItem.children[i], refItem)) {
						hasChildren = true;
					} else if (menuItem.children[i].children) {
						hasChildren = $scope.hasChildrenOrEqual(menuItem.children[i], refItem);
					}

					if (hasChildren) {
						return true;
					}
				}
			}

			return hasChildren;
		};

		$scope.onClickMenuItem = function (menuItem, $event) {
			if ($event) {
				$event.stopPropagation();
				if (menuItem.clickMeasureKey) {
					engagementScoreService.track(menuItem.clickMeasureKey);
				}
				if (menuItem.states) {
					$rootScope.$broadcast(Vgr.constants.evtMenuLinkClick);
					$state.go(menuItem.states[0], menuItem.stateParams);
				}
			} else {
				$scope.toggleAllRelatedToggledItems($scope.menu, menuItem);
			}
			$scope.untoggleAllUnrelatedToggledItems($scope.menu, menuItem);
			$scope.unsetAllItemsActive($scope.menu, menuItem);
			menuItem.toggled = !menuItem.toggled;
			if (menuItem.children) {
				menuItem.active = !menuItem.active;
			} else {
				menuItem.active = true;
			}
		};

		$scope.close = function () {
			$rootScope.$broadcast(Vgr.constants.evtCloseUserNavBar);
		};

		function setShowMenuItemRecursively(menuItem) {
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				menuItem.children.forEach(function (child) {
					setShowMenuItemProperties(child);
				});
			}
		}

		function setShowMenuItemProperties(menuItem) {
			if (menuItem.getFeatureAccess) {
				setShowMenuItem(menuItem, menuItem.getFeatureAccess());
			} else {
				setShowMenuItem(menuItem, Vgr.enumerations.userAccess.Enabled);
			}
		}

		function setShowMenuItem(menuItem, featureAccess) {
			menuItem.showMenuItem = featureAccess != Vgr.enumerations.userAccess.Hidden;

			if (featureAccess == Vgr.enumerations.userAccess.Enabled) {
				if (menuItem.isFree || menuItem.isMaster) {
					menuItem.featureAccess = featureAccess;
				} else if (accountService.isMarketProfile()) {
					menuItem.featureAccess = Vgr.enumerations.userAccess.Disabled;
				} else {
					menuItem.featureAccess = featureAccess;
				}
			} else {
				menuItem.featureAccess = featureAccess;
			}

			if (menuItem.showMenuItem) {
				setShowMenuItemRecursively(menuItem);
			}
		}
	}
]);
