angular.module("componentes").directive("vgrPage", function () {
	"use strict";
	return {
		scope: true,
		controller: "vgrPageCtrl",
		controllerAs: "ctrl",
		transclude: {
			messageSlot: "?message",
			contentSlot: "content"
		},
		templateUrl: "componentes/angular-material/custom/vgrPage/vgrPage_template.html",
		bindToController: {}
	};
});
