angular.module("componentes").factory("fileService", [
	"$q",
	"$http",
	"accountService",
	function ($q, $http, accountService) {
		"use strict";

		function getBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			return (
				Vgr.constants.sharedServicesHostUrl +
				Vgr.resources.sharedServices.files.replace("[ORGANIZATION_ID]", organizationId)
			);
		}

		var _uploadAll = function (files, folder) {
			var deferred = $q.defer();

			var uploadPromises = [];
			files.forEach(function (file) {
				const path = folder + "/" + Vgr.util.uuid() + "/" + file.Name + "." + file.Extension;
				uploadPromises.push(_upload(file.lfFile, path));
			});

			$q.all(uploadPromises).then(
				function (responses) {
					deferred.resolve(responses);
				},
				function (responses) {
					deferred.reject(responses);
				}
			);

			return deferred.promise;
		};

		var _upload = function (file, path) {
			var deferred = $q.defer();
			if (file.url) {
				deferred.resolve(file.path);
				return deferred.promise;
			}

			const reader = new FileReader();
			reader.onload = function (loadEvent) {
				const arrayBuffer = loadEvent.target.result;
				const byteArray = Vgr.util.arrayBufferToUint8Array(arrayBuffer);

				var req = {
					method: "post",
					data: {
						data: byteArray,
						path: path
					},
					url: getBaseRequestUrl()
				};

				$http(req).then(
					function (response) {
						deferred.resolve(response.data.fileKey);
					},
					function (responses) {
						deferred.reject(responses);
					}
				);
			};

			reader.readAsArrayBuffer(file);
			return deferred.promise;
		};

		var _getSignedURL = function (p_key) {
			var req = {
				method: "post",
				url: getBaseRequestUrl() + "/signed",
				data: { fileKeys: [p_key] }
			};

			return $http(req);
		};

		return {
			getSignedURL: _getSignedURL,
			uploadAll: _uploadAll
		};
	}
]);
