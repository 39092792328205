(function () {
	"use strict";

	angular.module("componentes", [
		"ui.router",
		"ui.router.state.events",
		"LocalStorageModule",
		"ngMaterial",
		"ngMessages",
		"md.data.table",
		"ngResource",
		"ui.utils.masks",
		"lfNgMdFileInput",
		"vAccordion",
		"ui.mask",
		"disableAll"
	]);
})();

(function () {
	"use strict";

	if (!function f() {}.name) {
		Object.defineProperty(Function.prototype, "name", {
			get: function () {
				var name = this.toString().match(/^function\s*([^\s(]+)/)[1];
				// For better performance only parse once, and then cache the
				// result through a new accessor for repeated access.
				Object.defineProperty(this, "name", { value: name });
				return name;
			}
		});
	}
})();

angular.module("componentes").filter("cut", function () {
	return Vgr.util.cut;
});
