angular.module("componentes").directive("actionButton", function () {
	"use strict";

	return {
		scope: true,
		replace: false,
		transclude: true,
		controller: "actionButtonCtrl",
		controllerAs: "ctrl",
		bindToController: {
			icon: "@",
			iconFa: "@",
			loading: "=?",
			selecting: "@?",
			buttonClasses: "@",
			isDisabled: "=",
			fill: "@?",
			tooltip: "@?",
			disabledTooltip: "@?",
			isExecuting: "=",
			notRaised: "@?"
		},
		templateUrl: "componentes/angular-material/custom/actionButton/actionButton_template.html",
		link: function (scope, element, attrs) {
			scope.$watch(
				function () {
					return scope.$eval(attrs.btnLoading);
				},
				function (value) {
					if (value) {
						if (!attrs.hasOwnProperty("ngDisabled")) {
							element.addClass("disabled").attr("disabled", "disabled");
						}

						element.data("resetText", element.html());
						element.html('<i class="fa fa-circle-o-notch fa-spin m-r-5"></i>' + element.data("loading-text"));
					} else {
						if (!attrs.hasOwnProperty("ngDisabled")) {
							element.removeClass("disabled").removeAttr("disabled");
						}

						element.html(element.data("resetText"));
					}
				}
			);
			scope.$watch(
				function () {
					return scope.$eval(attrs.btnSaved);
				},
				function (value) {
					if (value) {
						if (element.data("loaded-text")) {
							element.data("resetText", element.html());
							element.html(element.data("loaded-text"));
						} else {
							element.data("resetText", element.html());
							element.html(element.data("saved-text"));
						}
					} else {
						element.html(element.data("resetText"));
					}
				}
			);
		}
	};
});

Cmp.constructors.ActionButton = function ActionButton(
	label,
	icon,
	disabled,
	onClick,
	tooltip,
	disabledTooltip,
	show,
	buttonClasses,
	name,
	supressToast,
	hasPermission
) {
	"use strict";

	this.label = label;
	this.icon = icon;
	this.disabled = disabled;
	this.tooltip = tooltip;
	this.disabledTooltip = disabledTooltip;
	this.isExecuting = false;
	this.show = show || typeof show === "undefined";
	this.buttonClasses = buttonClasses;
	this.name = name;
	this.supressToast = supressToast;
	this.hasPermission = hasPermission;

	Object.defineProperty(this, "isDisabled", {
		get: function () {
			if (this.disabled instanceof Function) return this.disabled();
			else return this.disabled;
		},
		enumerable: true
	});

	var options = this;
	this.onClick = function ($event, param) {
		if ($event) $event.stopPropagation();

		if (!options.isExecuting) {
			options.isExecuting = true;
			var promise = onClick(param);
			if (promise && promise.then) promise.then(resetExecuting, resetExecuting);

			return promise;
		}
	};

	function resetExecuting() {
		options.isExecuting = false;
	}
};
