angular.module("componentes").controller("MultiSelectCtrl", [
	"$rootScope",
	"$scope",
	"$element",
	function ($rootScope, $scope, $element) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.isRequired = $scope.$ctrl.isRequired ? $scope.$ctrl.isRequired : false;
			$scope.selectedOptions = [];
			$scope.searchTerm = "";
			$scope.clearSearchTerm = function () {
				$scope.searchTerm = "";
			};

			$element.find("input").on("keydown", function (ev) {
				ev.stopPropagation();
			});

			if ($scope.$ctrl.initialValues) {
				$scope.selectedOptions = $scope.$ctrl.initialValues;
			}
		};

		$scope.$watch("selectedOptions", function (newVal, oldVal) {
			if (newVal && Array.isArray(newVal) && $scope.$ctrl.options) {
				$scope.selectAll = newVal.length == $scope.$ctrl.options.length ? true : false;
			}
			$scope.$ctrl.onSelectedValuesChanged(newVal);
		});

		$scope.isOptionSelected = function (option) {
			return $scope.selectAll || ($scope.selectedOptions && $scope.selectedOptions.includes(option));
		};

		$scope.onSelectAll = function () {
			if (!$scope.selectAll) {
				$scope.selectedOptions = [];
			}
		};

		$scope.filterFn = function (element) {
			if (!element[$scope.$ctrl.displayProperty] || typeof element[$scope.$ctrl.displayProperty] != "string") {
				return false;
			}

			return Vgr.util.isStringIncluded(element[$scope.$ctrl.displayProperty], $scope.searchTerm);
		};

		this.$onChanges = function (obj) {
			if (obj.clear && obj.clear.currentValue != obj.clear.previousValue) {
				if (obj.clear.currentValue) {
					clearValues();
				}
			}
		};

		function clearValues() {
			$scope.selectedOptions = [];
		}
	}
]);
