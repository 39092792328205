angular.module("componentes").controller("SelectFieldV3Ctrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var idField;
		var _list = [];

		this.$onInit = function () {
			$scope.placeholder = $scope.ctrl.label ? $scope.ctrl.label : $scope.ctrl.placeholder;

			Object.defineProperty($scope, "list", {
				get: function () {
					return _list;
				},
				set: function (value) {
					_list = value;
					if (_list && !$scope.ctrl.ignoreAutomaticSort) {
						_list.sort(sortObjects);
					}
				},
				enumerable: true
			});

			if (!$scope.ctrl.hasOwnProperty("isRequired")) {
				$scope.ctrl.isRequired = false;
			}
			if (!$scope.ctrl.hasOwnProperty("isDisabled")) {
				$scope.ctrl.isDisabled = false;
			}
			if (!$scope.ctrl.hasOwnProperty("canSetUnavailableValue")) {
				$scope.ctrl.canSetUnavailableValue = false;
			}

			createModelWatchToSetUnavailableValue();

			idField = $scope.ctrl.idField ? $scope.ctrl.idField : "Id";

			$scope.modelOptions = {
				trackBy: "$value." + idField
			};
		};

		function sortObjects(objA, objB) {
			return objA[$scope.ctrl.displayField].toString().localeCompare(objB[$scope.ctrl.displayField].toString());
		}

		$scope.$watch("ctrl.list", function (newValue, oldValue) {
			if (!newValue) {
				$scope.list = [];
			} else {
				if ($scope.ctrl.model && $scope.ctrl.canSetUnavailableValue) {
					addInList(newValue, $scope.ctrl.model);
				}
				$scope.list = newValue;
				addEmptyFieldIfNecessary();
			}
		});

		function createModelWatchToSetUnavailableValue() {
			if (!$scope.ctrl.canSetUnavailableValue) {
				return;
			}

			$scope.$watch("ctrl.model", function (newValue, oldValue) {
				if (!newValue || !$scope.list) {
					return;
				}

				addInList($scope.list, newValue);
			});
		}

		function addEmptyFieldIfNecessary() {
			if (!$scope.ctrl.hasEmptyField || !$scope.list || $scope.list.length == 0) {
				return;
			}

			if ($scope.list[0][$scope.ctrl.displayField] == "") {
				return;
			}

			var newItem = {};
			newItem[idField] = undefined;
			newItem[$scope.ctrl.displayField] = "";

			$scope.list.unshift(newItem);
		}

		function addInList(list, object) {
			for (var index in list) {
				var item = list[index];
				if (item[idField] == object[idField]) {
					return;
				}
			}
			list.push(object);
		}

		$scope.onChange = function (selected) {
			if ($scope.ctrl.change) {
				$scope.ctrl.change(selected);
			}
		};
	}
]);
