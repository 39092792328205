angular.module("componentes").directive("googleMap", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			scope: true,
			replace: true,
			controller: "GoogleMapCtrl",
			controllerAs: "ctrl",
			templateUrl: "componentes/angular-material/custom/googleMap/googleMap_template.html",
			bindToController: {
				options: "=",
				initOptions: "=?"
			},
			link: function (scope, element, attrs) {
				scope.ctrl.mapId = "map_" + Cmp.util.uuid();
				$timeout(function () {
					scope.ctrl.options.init(scope.ctrl.mapId, scope.ctrl.initOptions);
				}, 100);
			}
		};
	}
]);
