angular.module("componentes").directive("containerMenuButton", function () {
	"use strict";

	return {
		scope: true,
		controller: "ContainerMenuButtonCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/containerMenuButton/containerMenuButton_template.html",
		bindToController: {
			menuItems: "=",
			label: "@",
			dropdownLabel: "@",
			buttonLabel: "@",
			buttonClasses: "@",
			iconFontAwesome: "@",
			iconSvg: "@",
			iconName: "@",
			iconClass: "@",
			forceDropdown: "@",
			isDisabled: "=?"
		}
	};
});

Cmp.constructors.MenuButton = function MenuButton(label, icon, menuItems, hide, iconSvg) {
	"use strict";

	this.label = label;
	this.icon = icon;
	this.menuItems = menuItems;
	this.hide = hide;
	this.iconSvg = iconSvg;
};

Cmp.constructors.MenuButtonItem = function MenuButtonItem(title, method, icon, iconSvg) {
	"use strict";

	this.title = title;
	this.method = function ($event, param) {
		$event.stopPropagation();
		method(param);
	};
	this.icon = icon;
	this.iconSvg = iconSvg;
};
