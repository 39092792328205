angular.module("componentes").directive("pdf", function () {
	"use strict";
	return {
		restrict: "E",
		link: function (scope, element, attrs) {
			var url = scope.$eval(attrs.src);
			scope.$watch(attrs.src, function (newVal) {
				if (newVal) {
					element.replaceWith('<object flex type="application/pdf" data="' + newVal + '"></object>');
				}
			});
		}
	};
});
