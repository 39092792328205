angular.module("componentes").directive("imgSlider", function () {
	"use strict";
	return {
		scope: { url: "@" },
		replace: true,
		controller: "imgSliderCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/imgSlider/imgSlider_template.html",
		bindToController: {
			pictureList: "=",
			numberOfImagesScreenXs: "=",
			numberOfImagesScreenGt: "="
		}
	};
});
