angular.module("componentes").controller("actionButtonCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			$scope.ctrl.loading = Vgr.constants.loadingStates.IDLE;
			$scope.ctrl.materialClass = $scope.ctrl.notRaised ? "" : "md-raised";
		};

		$scope.isLoading = function () {
			if ($scope.ctrl.selecting) {
				return $scope.isSelecting();
			}
			return $scope.ctrl.loading === Vgr.constants.loadingStates.LOADING;
		};

		$scope.isSelecting = function () {
			return (
				$scope.ctrl.loading === Vgr.constants.loadingStates.LOADING ||
				$scope.ctrl.selecting == Vgr.constants.loadingStates.LOADING
			);
		};

		$scope.isIdle = function () {
			return $scope.loading === Vgr.constants.loadingStates.IDLE;
		};
	}
]);
