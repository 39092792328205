angular.module("componentes").controller("DateFieldCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		var chromeVersion = Vgr.util.getChromeVersion();

		this.$onInit = function () {
			configureClearButton();
			if ($scope.ctrl.ignoreDateLimits) {
				$scope.minDate = "";
				$scope.maxDate = "9999-12-31";
			} else {
				setMinDate();
				setMaxDate();
			}
			if ($scope.ctrl.initCurrentDate) {
				if (!$scope.ctrl.field) {
					$scope.ctrl.field = new Date();
				}
			}
		};

		$scope.$watch("ctrl.field", function (newValue) {
			if ($scope.ctrl.changed) {
				$scope.ctrl.changed();
			}
		});

		function configureClearButton() {
			if (chromeVersion && chromeVersion > 82) {
				$scope.showClearButton = true;
			}
		}

		function setMinDate() {
			if ($scope.ctrl.minDate) {
				$scope.minDate = $scope.ctrl.minDate;
			} else {
				$scope.minDate = "1900-01-01";
			}
		}

		function setMaxDate() {
			if ($scope.ctrl.maxDate) {
				$scope.maxDate = $scope.ctrl.maxDate;
			} else {
				$scope.maxDate = "2078-12-31";
			}
		}

		$scope.clearDate = function () {
			$scope.ctrl.field = null;
		};

		$scope.$watch("ctrl.minDate", function (newValue) {
			if (!$scope.ctrl.ignoreDateLimits) {
				setMinDate();
			}
		});

		$scope.$watch("ctrl.maxDate", function (newValue) {
			if (!$scope.ctrl.ignoreDateLimits) {
				setMaxDate();
			}
		});
	}
]);
