angular.module("componentes").controller("TextAreaCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.onChange = function () {
			if ($scope.ctrl.onChange) {
				$scope.ctrl.onChange($scope.ctrl.field);
			}
		};
	}
]);
