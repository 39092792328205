angular.module("componentes").controller("FileUploadV2Ctrl", [
	"$scope",
	"$rootScope",
	"$q",
	"fileService",
	function ($scope, $rootScope, $q, fileService) {
		"use strict";

		$scope.myfiles = { files: [] };
		$scope.filesDTO = [];
		$scope.fileApi = {};
		$scope.fileOptions = {
			browseIconCls: "fileUploadIcon md-accent",
			removeIconCls: "fileRemoveIcon",
			captionIconCls: "fileCaptionIcon",
			unknowIconCls: "fileUnknownIcon"
		};

		var isUploading = false;

		this.$onInit = function () {
			if ($scope.ctrl.options) {
				$scope.ctrl.options.isUploading = function () {
					return isUploading;
				};
			}

			getFileList();
			$scope.hidePreview = $scope.ctrl.options.hidePreview;

			$scope.ctrl.options.addFile = function (file, fileName, fileExtension) {
				$scope.fileOptions.api.addFile(file, fileName, fileExtension);
			};

			$scope.ctrl.options.hasChangedFiles = function () {
				return $scope.getRemovedFiles().length > 0 || $scope.getNewFiles().length > 0;
			};

			$scope.ctrl.options.updateFiles = function () {
				var newFiles = $scope.getNewFiles();
				if ($scope.invalidFilesSize(newFiles) && $scope.ctrl.options.fileService !== "mixed") {
					var deferred = $q.defer();
					deferred.reject({ d: '{"success": "false", "errorCode": "FILE_GREATER_THAN_MAX_SIZE"}' });
					return deferred.promise;
				}

				isUploading = true;

				var deletedIds = $scope.getRemovedFilesIdsString();
				var removedFiles = $scope.getRemovedFiles();

				var promise = null;
				if ($scope.ctrl.options instanceof Cmp.constructors.FileOptions) {
					promise = $scope.ctrl.options.update(newFiles.concat(removedFiles));
				} else if (removedFiles.length == 1) {
					promise = $scope.ctrl.options.update(newFiles, deletedIds, removedFiles[0].Name);
				} else {
					promise = $scope.ctrl.options.update(newFiles, deletedIds);
				}

				promise.then(success, fail);

				return promise;
			};

			$scope.ctrl.options.addFiles = function (pathList) {
				if (pathList && Array.isArray(pathList) && pathList.length > 0) {
					$scope.getFilesFromPathList(pathList, true);
				}
			};
		};

		function receiveBytesFromServer(list) {
			$scope.filesDTO = [];
			if (list && $scope.fileOptions.api) {
				var filesDTO = list;
				filesDTO.forEach(function (fileDTO) {
					if (fileDTO && !fileDTO.S3Key) {
						var file = Vgr.util.fileDtoToFile(fileDTO);
						$scope.fileOptions.api.addFile(file, fileDTO.Name, fileDTO.Extension);
						$scope.filesDTO.push(fileDTO);
					}
				});
			}
		}

		function getFilesFromPathList(pathList) {
			var promises = [];
			var promisesPaths = [];

			pathList.forEach(function (path) {
				if (path instanceof Object) {
					var key = path.Path;
					if (key) {
						promises.push(fileService.getSignedURL(key, "get"));
						promisesPaths.push(path);
					}
				}
			});

			var allPromise = $q.all(promises);

			allPromise.then(function (responses) {
				responses.forEach(function (response) {
					var file = {
						url: response.data[0].fileLink,
						path: promisesPaths[responses.indexOf(response)].Path
					};
					$scope.fileOptions.api.addFile(file, "", "");
				});
			});
		}

		function getFileList() {
			if ($scope.ctrl.options.get) {
				$scope.ctrl.options.get().then(function (list) {
					getFilesFromPathList(list);
					receiveBytesFromServer(list);
				});
			}
		}

		$scope.getNewFiles = function () {
			var newFiles = [];

			if ($scope.myfiles.files.length > 0) {
				if ($scope.filesDTO) {
					newFiles = $scope.myfiles.files.filter(function (file) {
						return !$scope.filesDTO.find(function (fileDTO) {
							return fileDTO.HashID ? fileDTO.HashID == file.lfFile.HashID : fileDTO.ID == file.lfFile.ID;
						});
					});
				}
			}

			return newFiles;
		};

		$scope.invalidFilesSize = function (files) {
			for (var i = 0; i < files.length; i++) {
				if (
					(files[i].lfFile.size > Vgr.constants.MAX_FILE_SIZE && Vgr.constants.MAX_FILE_SIZE !== 0) ||
					($scope.ctrl.options.maxUploadFileSize && files[i].lfFile.size > $scope.ctrl.options.maxUploadFileSize)
				) {
					return true;
				}
			}
			return false;
		};

		$scope.getRemovedFilesIdsString = function () {
			var removedFiles = $scope.getRemovedFiles();
			var removedIds = "";
			if (removedFiles.length > 0) {
				var Ids = [];
				var HashIds = [];
				removedFiles.forEach(function (element, index, array) {
					Ids[index] = element.ID;
					if (element.HashID) {
						HashIds.push(element.HashID);
					}
				});
				if (HashIds.length > 0) {
					removedIds = HashIds.join(", ");
				} else {
					removedIds = Ids.join(", ");
				}
			}
			return removedIds;
		};

		$scope.getRemovedFiles = function () {
			var removedFiles = [];

			if ($scope.filesDTO) {
				removedFiles = $scope.filesDTO.filter(function (fileDTO) {
					return !$scope.myfiles.files.find(function (file) {
						return fileDTO.HashID ? fileDTO.HashID == file.lfFile.HashID : fileDTO.ID == file.lfFile.ID;
					});
				});
			}

			removedFiles.forEach(function (file) {
				file.IsDeleted = true;
			});

			return removedFiles;
		};

		var success = function (response) {
			isUploading = false;
			if (!$scope.ctrl.options.fileService || $scope.ctrl.options.fileService !== "mixed") {
				getFileList();
			}
		};

		var fail = function (response) {
			isUploading = false;
			$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, getFailMessage(response));
		};

		function getFailMessage(response) {
			if (response && response.data && response.data.length && response.data.length > 0) {
				var errorMessage = response.data[0].message;
				if (errorMessage) {
					return errorMessage;
				}
			}
			return $rootScope.labels.SAVE_FILE_ERROR;
		}

		$scope.getPreview = function () {
			return $scope.ctrl.removePreview ? null : "";
		};

		$scope.$watch("ctrl.options.pathList", function (newVal) {
			if (newVal) {
				$scope.getFilesFromPathList(newVal, true);
			}
		});

		$scope.$watch("myfiles.files.length", function (newVal, oldVal) {
			if (newVal != oldVal) {
				$scope.ctrl.options.blockActions = true;
				var deferred = $q.defer();
				$scope.ctrl.options.updateFiles().then(
					function (response) {
						updateFileList(response, deferred);
						$scope.ctrl.options.blockActions = false;
					},
					function (error) {
						deferred.reject(error);
						$scope.ctrl.options.blockActions = false;
					}
				);
				return deferred.promise;
			}
		});

		function updateFileList(response, deferred) {
			if (response && Array.isArray(response)) {
				$scope.ctrl.options.fileList = [];
				response.forEach(function (file) {
					$scope.ctrl.options.fileList.push({
						Path: file,
						Name: file.substring(file.lastIndexOf("/") + 1)
					});
				});
			}
		}

		$scope.$watch("ctrl.options.refreshFiles", function (newVal, oldVal) {
			if (newVal != oldVal) {
				getFileList();
			}
		});
	}
]);
