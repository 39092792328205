angular.module("componentes").factory("imageService", [
	"localStorageService",
	function (localStorageService) {
		"use strict";

		const imagesCacheKey = Vgr.constants.cacheKeysPrefix.images;

		var _set = function (key, signedURL) {
			if (!localStorageService.get(imagesCacheKey + key)) {
				localStorageService.set(imagesCacheKey + key, {
					url: signedURL,
					date: new Date().getTime()
				});
			}
		};

		var _get = function (p_key) {
			var localStorageImage = localStorageService.get(imagesCacheKey + p_key);
			if (!localStorageImage) {
				return null;
			}
			var dateNowTime = new Date().getTime();
			var uploadedDateTime = localStorageImage.date;
			var secondsToExpire = parseInt(localStorageImage.url.split("Expires=")[1].split("&")[0]);
			var expiryTime = uploadedDateTime + secondsToExpire * 60;
			if (expiryTime > dateNowTime) {
				return localStorageImage;
			}
			return null;
		};

		return {
			set: _set,
			get: _get
		};
	}
]);
