angular.module("componentes").component("multiSelect", {
	controller: "MultiSelectCtrl",
	templateUrl: "componentes/angular-material/custom/multiSelect/multiSelect_template.html",
	bindings: {
		label: "@",
		searchLabel: "@",
		key: "@",
		displayProperty: "@",
		customClasses: "@?",
		options: "<",
		initialValues: "<?",
		onSelectedValuesChanged: "<",
		showSelectAll: "<",
		clear: "<",
		isRequired: "<?",
		isDisabled: "<?"
	}
});
