angular.module("componentes").directive("selectField", function () {
	"use strict";

	return {
		scope: true,
		controller: "SelectFieldCtrl",
		controllerAs: "ctrl",
		replace: true,
		templateUrl: "componentes/angular-material/custom/selectField/selectField_template.html",
		bindToController: {
			label: "@",
			name: "@",
			model: "=",
			valueField: "@",
			displayField: "@",
			isRequired: "=",
			error: "=",
			loadList: "=?",
			loadListItem: "=?",
			list: "=?",
			isDisabled: "@?",
			isImageSelect: "=?",
			change: "=",
			hasEmptyField: "=",
			customClass: "@",
			ignoreAutomaticSort: "@?",
			placeholder: "@",
			helpInfo: "@?"
		}
	};
});
