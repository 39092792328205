angular.module("componentes").directive("selectFieldV3", function () {
	"use strict";

	return {
		scope: {},
		controller: "SelectFieldV3Ctrl",
		controllerAs: "ctrl",
		replace: true,
		templateUrl: "componentes/angular-material/custom/selectField/selectFieldV3_template.html",
		bindToController: {
			label: "@",
			placeholder: "@",
			name: "@?",

			model: "=",
			idField: "@",
			displayField: "@",

			isRequired: "<?",
			isDisabled: "<?",
			hasEmptyField: "<?",
			ignoreAutomaticSort: "<?",
			canSetUnavailableValue: "<?",

			list: "<",

			change: "<?",

			messages: "=?"
		}
	};
});
