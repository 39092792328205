angular.module("componentes").controller("GridSimpleV2Ctrl", [
	"$scope",
	"$mdDialog",
	function ($scope, $mdDialog) {
		"use strict";

		$scope.selected = [];

		$scope.getDescendantProp = Vgr.util.getDescendantProp;

		$scope.getFormattedField = function (row, column) {
			var value = Vgr.util.getDescendantProp(row, column.property);

			if (column.type === Vgr.enumerations.gridSimple.columnType.date) return convertDateValue(value);
			else if (column.type === Vgr.enumerations.gridSimple.columnType.number)
				return Vgr.util.formatUnitDecimalPlaces(value, column.decimalPlaces);

			return value;
		};

		function convertDateValue(value) {
			if (value) {
				var isDate = moment(value).isValid();
				if (isDate) {
					return Vgr.date.fromDateToString(value);
				}
			}
			return value;
		}

		$scope.onAddClick = function () {
			if ($scope.ctrl.btnAddClick) {
				var addResult = $scope.ctrl.btnAddClick();
				if (addResult) {
					addResult.then(
						function (item) {
							$scope.ctrl.list.push(item);
						},
						function () {}
					);
				}
			}
		};

		$scope.onDeleteSingleItemClick = function (item) {
			var index = getIndexFromItemBySelectId(item);
			if (index >= 0) {
				$scope.ctrl.list.splice(index, 1);
			}
		};

		$scope.onDeleteSelectedClick = function () {
			var indexes = getIndexesFromArrayBySelectId($scope.selected);
			if (indexes.length > 0) {
				var i = indexes.length;
				while (i--) {
					$scope.ctrl.list.splice(indexes[i], 1);
				}
			}
			$scope.selected = [];
		};

		function getIndexFromItemBySelectId(item) {
			for (var i = 0; i < $scope.ctrl.list.length; ++i) {
				if ($scope.ctrl.list[i][$scope.ctrl.selectId] == item[$scope.ctrl.selectId]) {
					return i;
				}
			}
			return -1;
		}

		function getIndexesFromArrayBySelectId(array) {
			var indexes = [];
			for (var i = 0; i < $scope.ctrl.list.length; ++i) {
				for (var j = 0; j < array.length; ++j) {
					if ($scope.ctrl.list[i][$scope.ctrl.selectId] == array[j][$scope.ctrl.selectId]) {
						indexes.push(i);
					}
				}
			}
			return indexes;
		}

		$scope.isToShowDeleteComponents = function () {
			return (
				$scope.ctrl.defaultActions &&
				$scope.ctrl.defaultActions.indexOf(Vgr.enumerations.gridSimple.defaultActions.delete) > -1
			);
		};

		$scope.isToShowAddButton = function () {
			return (
				$scope.ctrl.defaultActions &&
				$scope.ctrl.defaultActions.indexOf(Vgr.enumerations.gridSimple.defaultActions.add) > -1
			);
		};

		$scope.getClass = function (column, item) {
			if (column.getClass) {
				return column.getClass(item);
			} else {
				return "";
			}
		};
	}
]);
