angular.module("componentes").component("groupSelectFieldV2", {
	controller: "GroupSelectFieldV2Ctrl",
	templateUrl: "componentes/angular-material/custom/groupSelectFieldV2/groupSelectFieldV2_template.html",
	bindings: {
		label: "@?",
		placeholder: "@?",
		//name: '@',

		customClass: "@?",

		keyProperty: "@?",
		displayProperty: "@?",
		categoryProperty: "@?",
		defaultCategoryLabel: "@?",

		isRequired: "<?",
		items: "<",

		setter: "<?",
		callback: "<",

		clear: "<?"
	}
});
