angular.module("componentes").directive("reportView", function () {
	"use strict";
	return {
		controller: "ReportViewCtrl",
		controllerAs: "ctrl",
		replace: true,
		templateUrl: "componentes/angular-material/custom/report/reportView_template.html",
		bindToController: {
			options: "="
		}
	};
});
