angular.module("componentes").directive("s3Img", function () {
	"use strict";
	return {
		scope: { url: "@" },
		replace: true,
		controller: "s3ImgCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/s3Img/s3Img_template.html",
		bindToController: {
			key: "@",
			url: "@?",
			width: "@?",
			height: "@?",
			maxHeight: "@?",
			maxWidth: "@?",
			minHeight: "@?",
			minWidth: "@?",
			containerWidth: "@?",
			containerHeight: "@?",
			defaultImg: "@",
			alignment: "@",
			imgClass: "@?",
			divClass: "@?"
		}
	};
});
