angular.module("componentes").controller("ToolbarButtonsCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.loadingBtn = "";
		function containsButton(type) {
			if (!$scope.ctrl.options || !$scope.ctrl.options.buttons) {
				return false;
			}

			for (var i = 0; i < $scope.ctrl.options.buttons.length; ++i) {
				if ($scope.ctrl.options.buttons[i] == type) {
					return true;
				}
			}
			return false;
		}

		$scope.shouldShowButton = function (type) {
			return containsButton(type);
		};

		$scope.options.activeButton = "";
		$scope.onToggleFilterButton = function () {
			if ($scope.options.activeButton == $rootScope.labels.FILTER) {
				$scope.options.activeButton = "";
			} else {
				$scope.options.activeButton = $rootScope.labels.FILTER;
			}
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onToggleFilter) {
				$scope.ctrl.options.events.onToggleFilter();
			}
		};

		$scope.onClickExcel = function () {
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onClickExcel) {
				$scope.ctrl.options.events.onClickExcel();
			}
		};

		$scope.onClickCsv = function () {
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onClickCsv) {
				$scope.ctrl.options.events.onClickCsv();
			}
		};

		$scope.onClickWord = function () {
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onClickWord) {
				$scope.ctrl.options.events.onClickWord();
			}
		};

		$scope.isExecuting = false;
		$scope.onClickEmail = function (email) {
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onClickEmail) {
				$scope.isExecuting = true;
				$scope.ctrl.options.events.onClickEmail(email).then(
					function () {
						$scope.isExecuting = false;
					},
					function () {
						$scope.isExecuting = false;
					}
				);
			}
		};

		$scope.onClickNew = function () {
			if ($scope.ctrl.options && $scope.ctrl.options.events && $scope.ctrl.options.events.onClickNew) {
				$scope.ctrl.options.events.onClickNew();
			}
		};

		$scope.isButtonActive = function (type) {
			return $scope.options.activeButton == type;
		};

		$scope.isButtonDisabled = function () {
			return $scope.ctrl.options.disabled;
		};

		$scope.isLoading = function (type) {
			return $scope.loadingBtn == type;
		};
	}
]);
