angular.module("componentes").controller("messageBarCtrl", [
	"$rootScope",
	"$scope",
	"$mdToast",
	"$timeout",
	"Notification",
	function ($rootScope, $scope, $mdToast, $timeout, Notification) {
		"use strict";

		$scope.messages = {};
		var onShowErrorMessage;

		this.$onInit = function () {
			onShowErrorMessage = $rootScope.$on(Vgr.constants.evtShowErrorMessage, function (event, data) {
				$scope.showMessageToast(data.text ? data.text : data, "error");
			});
		};

		this.$onDestroy = function () {
			// disable the listener
			onShowErrorMessage();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		$scope.showMessageToast = function (data, type) {
			if (data in $scope.messages || $rootScope.labels.UNAUTHORIZED_USER_ERROR in $scope.messages) {
				return;
			}

			if (jQuery.isEmptyObject($scope.messages)) {
				$scope.messages[data] = true;
				$timeout(function () {
					showToast(data, type);
				}, 500);
			} else {
				showToast(data, type);
			}
		};

		function showToast(data, type) {
			$scope.messages[data] = true;

			if (type === "info") {
				Notification.info({
					message: data.text ? data.text : data,
					templateUrl: "componentes/angular-material/custom/messageBar/toast_template.html"
				});
			}
			if (type === "success") {
				Notification.success(data.text ? data.text : data);
			}
			if (type === "error") {
				Notification.error(data.text ? data.text : data);
			}
			if (type === "warning") {
				Notification.warning({
					message: data.text ? data.text : data,
					templateUrl: "componentes/angular-material/custom/messageBar/toast_template.html"
				});
			}

			$timeout(function () {
				delete $scope.messages[data];
			}, 5000);
		}

		$scope.$on(Vgr.constants.evtShowSuccessMessage, function (_event, data) {
			$scope.showMessageToast(data.text ? data.text : data, "success");
		});

		$scope.$on(Vgr.constants.evtShowInfoMessage, function (_event, data) {
			$scope.showMessageToast(data.text ? data.text : data, "info");
		});

		$scope.$on(Vgr.constants.evtShowWarningMessage, function (_event, data) {
			$scope.showMessageToast(data.text ? data.text : data, "warning");
		});

		$scope.$on(Vgr.constants.evtConfirmOperation, function (_event, data) {
			$scope.showConfirmationToast(data.text ? data : { text: data }, "info");
		});

		$scope.$on(Vgr.constants.evtExecuteOperation, function (_event, data) {
			$scope.showExecuteToast(data.text ? data : { text: data }, "warning");
		});

		$scope.$on(Vgr.constants.evtDestroyGrid, function (_event) {
			$timeout(function () {
				$scope.messages = {};
				$mdToast.hide();
			}, 1000);
		});

		$scope.$on(Vgr.constants.evtCloseToast, function (_event, data) {
			if (data) {
				delete $scope.messages[data];
			}
		});

		$scope.showConfirmationToast = function (eventParam, type) {
			var eventData = eventParam && eventParam.data ? eventParam.data : eventParam;

			$scope.accept = function () {
				$rootScope.$broadcast(Vgr.constants.evtConfirmYes, eventData);
				$scope.kill(true);
			};

			$scope.deny = function () {
				$rootScope.$broadcast(Vgr.constants.evtConfirmNo, eventData);
				$scope.kill(true);
			};

			openNotification(eventParam);
		};

		$scope.showExecuteToast = function (eventParam, type) {
			var eventData = eventParam && eventParam.data ? eventParam.data : eventParam;

			$scope.execute = function () {
				$rootScope.$broadcast(Vgr.constants.evtExecuteAction, eventData, true);
				$scope.kill(true);
			};

			$scope.executeToastLabel = eventParam.label;

			openNotification(eventParam);
		};

		function openNotification(eventParam) {
			$scope.labels = $rootScope.labels;

			Notification.info({
				message: eventParam.text ? eventParam.text : eventParam,
				templateUrl: "componentes/angular-material/custom/messageBar/toast_template.html",
				closeOnClick: false,
				scope: $scope
			}).then(function (notificationScope) {
				$scope.kill = notificationScope.kill;
			});
		}
	}
]);
