angular.module("componentes").controller("s3ImgCtrl", [
	"$scope",
	"$rootScope",
	"fileService",
	"imageService",
	function ($scope, $rootScope, fileService, imageService) {
		"use strict";
		this.$onInit = function () {
			$scope.url = $scope.ctrl.url || "";

			if ($scope.ctrl.width || $scope.ctrl.height) {
				$scope.imgStyle =
					"{'width': '" +
					$scope.ctrl.width +
					"px','height': '" +
					$scope.ctrl.height +
					"px','max-height': '" +
					$scope.ctrl.height +
					"px', 'max-width': '" +
					$scope.ctrl.width +
					"px', 'min-height': '" +
					$scope.ctrl.height +
					"px', 'min-width': '" +
					$scope.ctrl.width +
					"px'}";
				$scope.divStyle = $scope.imgStyle;
			} else {
				$scope.imgStyle =
					"{'max-height': '" +
					$scope.ctrl.maxHeight +
					"px', 'max-width': '" +
					$scope.ctrl.maxWidth +
					"px', 'min-height': '" +
					$scope.ctrl.minHeight +
					"px', 'min-width': '" +
					$scope.ctrl.minWidth +
					"px'}";
				$scope.divStyle =
					"{'width': '" + $scope.ctrl.containerWidth + "px', 'height': '" + $scope.ctrl.containerHeight + "px'}";
			}
		};

		$scope.$watch("ctrl.key", function (newValue) {
			if (newValue) {
				$scope.isLoading = true;

				var cachedURL = imageService.get(newValue);
				if (cachedURL && cachedURL.url.length > 0) {
					$scope.url = cachedURL.url;
					$scope.isLoading = false;
				} else {
					fileService.getSignedURL(newValue, "GET").then(
						function (response, status, headers, config) {
							if (response && response.data && response.data.success) {
								imageService.set(newValue, response.data.content.value);
								$scope.url = response.data.content.value;
								$scope.isLoading = false;
							}
						},
						function () {
							$scope.isLoading = false;
						}
					);
				}
			}
		});
	}
]);
