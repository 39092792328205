angular.module("componentes").controller("ReportFiltersCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			$scope.buttonLabel = $scope.ctrl.options.buttonLabel;
		};

		$scope.onFilterClick = function () {
			if ($scope.ctrl.options.onFilterClick) {
				if ($scope.ctrl.options.filter && $scope.ctrl.options.filter.useTagId) {
					$scope.ctrl.options.onFilterClick($scope.ctrl.options.filter.useTagId);
				} else {
					$scope.ctrl.options.onFilterClick();
				}
			}
		};
	}
]);
