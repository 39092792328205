angular.module("componentes").directive("dateField", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			scope: true, //{},
			controller: "DateFieldCtrl",
			controllerAs: "ctrl",
			replace: true,
			templateUrl: "componentes/angular-material/custom/fields/dateField/dateField_template.html",
			bindToController: {
				label: "@",
				name: "@",
				isDisabled: "=",
				isRequired: "=",
				field: "=",

				maxlength: "@?",
				changed: "=?",
				initCurrentDate: "@",
				ignoreDateLimits: "=",
				minDate: "=?",
				maxDate: "=?"
			}
		};
	}
]);
