angular.module("componentes").controller("sideNavCtrl", [
	"$scope",
	"$rootScope",
	"$mdSidenav",
	"$mdComponentRegistry",
	function ($scope, $rootScope, $mdSidenav, $mdComponentRegistry) {
		"use strict";

		$scope.$on(Vgr.constants.evtOpenMenu, function () {
			$mdComponentRegistry.when("left").then(function () {
				$mdSidenav("left").toggle();
				$mdSidenav("right").close();
			});
		});

		$scope.$on(Vgr.constants.evtMenuLinkClick, function () {
			$mdSidenav("right").close();
			$mdSidenav("left").close();
			$scope.close();
		});

		$scope.$on(Vgr.constants.evtCloseUserNavBar, function () {
			$mdSidenav("right").close();
			$mdSidenav("left").close();
			$scope.close();
		});

		$scope.$on(Vgr.constants.evtOpenUserMenu, function () {
			$rootScope.sideNavRight = true;
			$mdComponentRegistry.when("right").then(function () {
				$mdSidenav("right").toggle();
				$mdSidenav("left").close();
			});
		});

		$scope.close = function () {
			$rootScope.sideNavRight = false;
			$mdComponentRegistry.when("right").then(function () {
				$mdSidenav("right").close();
				$mdSidenav("left").close();
			});
		};
	}
]);
