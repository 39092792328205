angular.module("componentes").controller("vgrPageCtrl", [
	"$rootScope",
	"$scope",
	"$timeout",
	function ($rootScope, $scope, $timeout) {
		"use strict";

		$scope.hideMenu = false;

		$scope.isLoading = false;

		$scope.$on(Vgr.constants.evtStartLoadingPage, function () {
			$scope.isLoading = true;
		});

		$scope.$on(Vgr.constants.evtFinishLoadingPage, function () {
			$timeout(function () {
				$scope.isLoading = false;
			});
		});

		$scope.$on(Vgr.constants.evtShowMenu, function (event, data) {
			if (data === false) {
				$scope.hideMenu = true;
			} else {
				$scope.hideMenu = false;
			}
		});

		$scope.getOnlyContent = function () {
			return $rootScope.onlyContent;
		};
	}
]);
