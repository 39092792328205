angular.module("componentes").directive("menuRight", function () {
	"use strict";

	return {
		scope: true,
		controller: "menuRightCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/sideNav/menuRight_template.html",
		bindToController: {}
	};
});
