angular.module("componentes").controller("imgSliderCtrl", [
	"$scope",
	"$mdMedia",
	function ($scope, $mdMedia) {
		"use strict";

		var pictureMainSelected;
		var firstImagePosition = 0;

		this.$onInit = function () {
			$scope.blockedBefore = true;
			pictureMainSelected = $scope.ctrl.pictureList[0];

			if ($scope.ctrl.numberOfImagesScreenGt) {
				$scope.numberOfPictures = $scope.ctrl.numberOfImagesScreenGt;
			} else {
				$scope.numberOfPictures = 3;
			}

			$scope.selctedPictureIndex = 0;

			$scope.getPictureList();
		};

		$scope.getPictureList = function (event) {
			var pictureList = [];
			if (event) {
				if (event === "next") {
					if (firstImagePosition >= $scope.ctrl.pictureList.length - $scope.numberOfPictures) {
						firstImagePosition = $scope.ctrl.pictureList.length - $scope.numberOfPictures;
						$scope.blockedNext = true;
						$scope.blockedBefore = false;
					} else {
						firstImagePosition++;
						if (firstImagePosition === $scope.ctrl.pictureList.length - $scope.numberOfPictures) {
							$scope.blockedNext = true;
							$scope.blockedBefore = false;
						} else {
							$scope.blockedNext = false;
							$scope.blockedBefore = false;
						}
					}
				} else if (event === "before") {
					if (firstImagePosition <= 0) {
						firstImagePosition = 0;
						$scope.blockedBefore = true;
						$scope.blockedNext = false;
					} else {
						firstImagePosition--;
						if (firstImagePosition === 0) {
							$scope.blockedBefore = true;
							$scope.blockedNext = false;
						} else {
							$scope.blockedNext = false;
							$scope.blockedBefore = false;
						}
					}
				}
			}
			for (var i = firstImagePosition; i < firstImagePosition + $scope.numberOfPictures; i++) {
				if ($scope.ctrl.pictureList) {
					pictureList.push($scope.ctrl.pictureList[i]);
				}
			}

			$scope.pictureListShow = pictureList;
			return $scope.pictureListShow;
		};

		$scope.pictureMain = function (pictureMain, index) {
			$scope.selctedPictureIndex = index;
			pictureMainSelected = pictureMain;
		};

		$scope.getPictureMainSelected = function () {
			return pictureMainSelected;
		};

		$scope.changeToNextPicture = function () {
			if ($scope.pictureListShow.length > $scope.selctedPictureIndex + 1) {
				$scope.selctedPictureIndex++;
				pictureMainSelected = $scope.pictureListShow[$scope.selctedPictureIndex];
			} else if (!$scope.blockedNext) {
				$scope.getPictureList("next");
				$scope.selctedPictureIndex--;
				$scope.changeToNextPicture();
			}
		};

		$scope.$watch(
			function () {
				if ($mdMedia("xs") || $mdMedia("sm")) {
					if ($scope.ctrl.numberOfImagesScreenXs) {
						$scope.numberOfPictures = $scope.ctrl.numberOfImagesScreenXs;
						$scope.blockedBefore = true;
						$scope.blockedNext = true;
						if ($scope.ctrl.pictureList.length > $scope.ctrl.numberOfImagesScreenXs) {
							$scope.blockedNext = false;
						}
					} else {
						if ($scope.ctrl.pictureList.length > 2) {
							$scope.numberOfPictures = 2;
						} else {
							$scope.numberOfPictures = $scope.ctrl.pictureList.length;
							$scope.blockedBefore = true;
							$scope.blockedNext = true;
							if ($scope.ctrl.pictureList.length > $scope.ctrl.numberOfImagesScreenXs) {
								$scope.blockedNext = false;
							}
						}
					}
				} else {
					if ($scope.ctrl.numberOfImagesScreenGt) {
						$scope.numberOfPictures = $scope.ctrl.numberOfImagesScreenGt;
					} else {
						if ($scope.ctrl.pictureList.length > 3) {
							$scope.numberOfPictures = 3;
						} else {
							$scope.numberOfPictures = $scope.ctrl.pictureList.length;
							$scope.blockedBefore = true;
							$scope.blockedNext = true;
						}
					}
				}
				return $mdMedia("xs");
			},
			function (newVal) {
				$scope.screenIsXs = $mdMedia("xs");
			}
		);
	}
]);
