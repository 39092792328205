angular.module("componentes").controller("MultiSelectV2Ctrl", [
	"$rootScope",
	"$scope",
	"$element",
	function ($rootScope, $scope, $element) {
		"use strict";

		const defaultIdField = "id";
		const defaultDisplayTextField = "displayText";

		var idField;

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;

			$scope.searchLabel = $scope.$ctrl.searchLabel
				? $scope.$ctrl.searchLabel
				: $scope.labels.SEARCH + " " + $scope.$ctrl.label;
			idField = $scope.$ctrl.key ? $scope.$ctrl.key : defaultIdField;
			$scope.displayTextField = $scope.$ctrl.displayProperty ? $scope.$ctrl.displayProperty : defaultDisplayTextField;

			if (!$scope.$ctrl.hasOwnProperty("showSelectAll")) {
				$scope.showSelectAll = true;
			} else {
				$scope.showSelectAll = $scope.$ctrl.showSelectAll;
			}

			$scope.$ctrl.isRequired = $scope.$ctrl.hasOwnProperty("isRequired") && $scope.$ctrl.isRequired;

			$element.find("input").on("keydown", function (ev) {
				ev.stopPropagation();
			});

			//nao funcionou
			$scope.modelOptions = {
				trackBy: "$value." + idField
			};

			clearSearchTerm();
			setValues($scope.$ctrl.setter);
		};

		$scope.onChange = function (newValue, oldValue, opt) {
			callback();
		};

		$scope.onCloseSelect = function () {
			clearSearchTerm();
		};

		function clearSearchTerm() {
			$scope.searchTerm = "";
		}

		$scope.filterBySearchText = function (element) {
			if (!element[$scope.displayTextField] || typeof element[$scope.displayTextField] != "string") {
				return false;
			}

			return Vgr.util.isStringIncluded(element[$scope.displayTextField], $scope.searchTerm);
		};

		this.$onChanges = function (obj) {
			if (obj.setter && !obj.setter.isFirstChange()) {
				setValues(obj.setter.currentValue);
			}
			if (obj.items && !obj.items.isFirstChange()) {
				setValues($scope.$ctrl.setter);
			}
			if (obj.clear) {
				if (obj.clear.currentValue) {
					setValues();
				}
			}
			$scope.$ctrl.isRequired = $scope.$ctrl.hasOwnProperty("isRequired") && $scope.$ctrl.isRequired;
		};

		function setValues(values) {
			if (values) {
				$scope.selectedItems = Vgr.util.intersectTwoArrayOfObjects($scope.$ctrl.items, values, idField);
			} else {
				$scope.selectedItems = [];
			}
			callback();
		}

		$scope.onSelectAll = function () {
			if ($scope.selectedItems.length === $scope.$ctrl.items.length) {
				$scope.selectedItems = [];
			} else if ($scope.selectedItems.length === 0 || $scope.selectedItems.length > 0) {
				$scope.selectedItems = $scope.$ctrl.items.slice(0);
			}
			callback();
		};

		$scope.isSelectAllIndeterminate = function () {
			return (
				$scope.selectedItems &&
				$scope.$ctrl.items &&
				$scope.selectedItems.length !== 0 &&
				$scope.selectedItems.length !== $scope.$ctrl.items.length
			);
		};

		$scope.isSelectAllChecked = function () {
			return $scope.selectedItems && $scope.$ctrl.items && $scope.selectedItems.length === $scope.$ctrl.items.length;
		};

		function callback() {
			if ($scope.$ctrl.callback) {
				var selectItems = formatSelectedItemsToCallback();

				$scope.$ctrl.callback(selectItems);
			}
		}

		function formatSelectedItemsToCallback() {
			return $scope.selectedItems.map(function (item) {
				var newItem = angular.copy(item);

				if (idField !== defaultIdField) {
					newItem[defaultIdField] = newItem[idField];
				}

				return newItem;
			});
		}
	}
]);
