angular.module("componentes").controller("VgrContainerCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$mdMedia",
	function ($scope, $rootScope, $state, $mdMedia) {
		"use strict";

		$scope.goBackToPreviousPage = function () {
			if ($scope.saveOptions.returnState) {
				$state.go($scope.saveOptions.returnState);
			} else if ($scope.saveOptions.back) {
				$scope.useBack($scope.saveOptions.back);
			} else if ($scope.getOptions().returnState) {
				$state.go($scope.getOptions().returnState);
			} else if ($scope.getOptions().back) {
				$scope.useBack($scope.getOptions().back);
			} else {
				window.history.back();
			}
		};

		$scope.buttonBar = $scope.saveOptions;

		$scope.useBack = function (back) {
			if (typeof back === "function") {
				back();
			} else if (typeof back === "string") {
				$state.go(back);
			}
		};

		$scope.getDescendantProp = Vgr.util.getDescendantProp;

		$scope.getOptions = function getOptions() {
			if ($scope.saveOptions && $scope.saveOptions.currentContext) {
				return $scope.getDescendantProp($scope.saveOptions, $scope.saveOptions.currentContext);
			} else {
				return $scope.saveOptions;
			}
		};

		$scope.options = {};
		$scope.$watch("ctrl.toolbarOptions", function (newVal) {
			if (newVal) {
				$scope.options = newVal;
			}
		});

		$scope.$watch("ctrl.saveOptions", function (newVal) {
			if (newVal) {
				$scope.saveOptions = newVal;
			}
		});

		$scope.containsCloseButton = function () {
			var buttons = $scope.getOptions().buttons;
			if (buttons && buttons.length > 0) {
				for (var i = 0; i < buttons.length; ++i) {
					if (buttons[i] === $rootScope.labels.CLOSE) {
						return true;
					}
				}
			}
			return false;
		};

		$scope.$watch(
			function () {
				return $mdMedia("gt-sm");
			},
			function (screenIsGtSm) {
				$scope.screenIsGtSm = screenIsGtSm;
			}
		);
	}
]);
