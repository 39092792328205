angular.module("componentes").controller("NumberFieldCtrl", [
	"$scope",
	"clientService",
	function ($scope, clientService) {
		"use strict";

		this.$onInit = function () {
			const decimals = clientService.getDecimalPlaces();
			$scope.decimals = $scope.ctrl.decimals || $scope.ctrl.decimals === 0 ? $scope.ctrl.decimals : decimals;
		};
	}
]);
