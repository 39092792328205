angular.module("componentes").directive("menu", function () {
	"use strict";

	return {
		scope: true,
		controller: "menuCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/sideNav/menu_template.html",
		bindToController: {}
	};
});

Cmp.constructors.DefaultMenu = function DefaultMenu(
	labels,
	userAccessService,
	stockControlAccess,
	accountService,
	internalTransferTypePreference
) {
	"use strict";

	var isSupplier = accountService.isSupplier();
	var viewDisposalPermission = userAccessService.hasAccess(Vgr.systemPermissions.ViewDisposal);

	var viewStorageViewGenerated = ViewGeneratedFromAllAreasOrUserAreas(userAccessService);
	var viewStorageViewStored = ViewStoredFromAllAreasOrUserAreas(userAccessService);
	var viewStorageViewInternalTransfer = userAccessService.hasAccess(Vgr.systemPermissions.ViewInternalTransfer);
	var viewStorageAnyView = canAccessStorageScreen(
		internalTransferTypePreference,
		viewStorageViewGenerated,
		viewStorageViewStored,
		viewStorageViewInternalTransfer
	);

	var viewGatheringPermission = viewStorageAnyView;

	var viewMarketPermission = userAccessService.hasAccess(Vgr.systemPermissions.ViewMarket);
	var goalAccess = userAccessService.getAccess(
		Vgr.systemPermissions.GoalView,
		Vgr.constants.featureToggles.permission.goalModule,
		null
	);
	var destinatorModuleAccess = userAccessService.getAccess(
		Vgr.systemPermissions.DestinatorDisposalManifestDestinatorView,
		Vgr.constants.featureToggles.permission.destinatorModule
	);

	var ibamaRappIntegrationFeatureAccess = userAccessService.getAccess(
		null,
		Vgr.constants.featureToggles.permission.ibamaRappIntegration,
		null,
		true
	);
	var dnitIntegrationFeatureAccess = userAccessService.getAccess(
		Vgr.systemPermissions.ViewDisposal,
		Vgr.constants.featureToggles.permission.dnitIntegration,
		null,
		true
	);
	var generateDmrAccess = userAccessService.getAccess(Vgr.systemPermissions.GenerateDmr);
	var esgFeatureAccess = userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.esgModule);
	var biFeatureAccess = userAccessService.getAccess(null, null, Vgr.constants.featureToggles.release.biModule);
	var isLowerGenerationPlan = userAccessService.hasAccess(
		null,
		Vgr.constants.featureToggles.permission.lowerGenerationPlan
	);

	var homeMenu = {
		text: function () {
			return labels.HOME;
		},
		states: isSupplier ? ["homeSupplier"] : ["home"],
		icon: "home",
		level: 0,
		isFree: isSupplier
	};

	var dashboardMenu = {
		text: function () {
			return labels.DASHBOARD;
		},
		clickMeasureKey: Vgr.trackings.dashboard.usingMenu,
		states: ["dashboard"],
		icon: "dashboard",
		level: 0
	};

	var residueManagementMenu = {
		text: function () {
			return labels.RESIDUE_MANAGEMENT;
		},
		icon: "swap_vert",
		level: 0,
		disabledHover: true,
		getFeatureAccess: function () {
			return viewGatheringPermission || viewDisposalPermission
				? Vgr.enumerations.userAccess.Enabled
				: Vgr.enumerations.userAccess.Hidden;
		},
		fixed: true,
		children: getRDMMenuChildren(
			labels,
			viewDisposalPermission,
			destinatorModuleAccess,
			stockControlAccess,
			viewStorageAnyView
		)
	};

	const residueManagementMenuLowerGeneration = {
		text: function () {
			return labels.RESIDUE_MANAGEMENT;
		},
		icon: "swap_vert",
		level: 0,
		getFeatureAccess: function () {
			return viewGatheringPermission || viewDisposalPermission
				? Vgr.enumerations.userAccess.Enabled
				: Vgr.enumerations.userAccess.Hidden;
		},
		states: ["residuesManagement"]
	};

	var supplierManagementMenu = {
		text: function () {
			return labels.SUPPLIER_MANAGEMENT;
		},
		icon: "home_work",
		level: 0,
		fixed: true,
		disabledHover: true,
		children: [
			{
				text: function () {
					return labels.CORPORATIVE_CONTROL;
				},
				states: ["supplierList"],
				svgImage: "img/icons/factory.svg",
				level: 1
			},
			{
				text: function () {
					return labels.UNIT_CONTROL;
				},
				states: ["transporterList", "transporterEdit"],
				icon: "local_shipping",
				level: 1
			}
		]
	};

	const supplierManagementMenuLowerGeneration = {
		text: function () {
			return labels.SUPPLIER_MANAGEMENT;
		},
		icon: "home_work",
		level: 0,
		states: ["supplierList"]
	};

	var residueMarketMenu = {
		text: function () {
			return labels.SUPPLIER_INDICATIONS;
		},
		states: ["residueMarketNew"],
		level: 0,
		icon: "attach_money",
		getFeatureAccess: function () {
			return viewMarketPermission ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
		},
		isFree: true
	};

	var pricingMenu = {
		text: function () {
			return labels.PRICING_CONSULT;
		},
		states: ["pricingConsult"],
		level: 0,
		icon: "insert_chart_outlined"
	};

	const statementsMenu = getStatementsMenu(
		labels,
		viewDisposalPermission,
		viewGatheringPermission,
		ibamaRappIntegrationFeatureAccess,
		dnitIntegrationFeatureAccess,
		generateDmrAccess,
		isLowerGenerationPlan
	);

	const businessInteligenceMenu = getBiMenu(
		labels,
		viewGatheringPermission,
		viewDisposalPermission,
		goalAccess,
		isLowerGenerationPlan
	);

	var dashboardEsgMenu = {
		text: function () {
			return labels.PAINEL_ESG;
		},
		svgImage: "img/icons/co2.svg",
		level: 0,
		states: ["dashboardEsg"],
		getFeatureAccess: function () {
			return esgFeatureAccess;
		}
	};

	var powerBiMenu = {
		text: function () {
			return labels.BI_DASHBOARD;
		},
		icon: "query_stats",
		level: 0,
		states: ["bi"],
		getFeatureAccess: function () {
			return biFeatureAccess;
		},
		showNewImage: true
	};

	if (isLowerGenerationPlan) {
		this.children = [
			homeMenu,
			residueManagementMenuLowerGeneration,
			supplierManagementMenuLowerGeneration,
			businessInteligenceMenu,
			statementsMenu
		];
	} else {
		this.children = [
			homeMenu,
			dashboardMenu,
			powerBiMenu,
			dashboardEsgMenu,
			residueManagementMenu,
			supplierManagementMenu,
			businessInteligenceMenu,
			statementsMenu,
			residueMarketMenu,
			pricingMenu
		];
	}
};

function ViewStoredFromAllAreasOrUserAreas(userAccessService) {
	return (
		userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromAllAreas) ||
		userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromUsersAreas)
	);
}

function ViewGeneratedFromAllAreasOrUserAreas(userAccessService) {
	return (
		userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromAllAreas) ||
		userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromUsersAreas)
	);
}

function canAccessStorageScreen(
	internalTransferTypePreference,
	viewStorageViewGenerated,
	viewStorageViewStored,
	viewStorageViewInternalTransfer
) {
	if (internalTransferTypePreference == Vgr.enumerations.internalGathering.internalTransferType.FullTransfer) {
		return viewStorageViewInternalTransfer || viewStorageViewGenerated || viewStorageViewStored;
	} else if (
		internalTransferTypePreference == Vgr.enumerations.internalGathering.internalTransferType.TwoStepTransfer
	) {
		return viewStorageViewGenerated || viewStorageViewStored;
	} else {
		return viewStorageViewStored;
	}
}

function getBiMenu(labels, viewGatheringPermission, viewDisposalPermission, goalAccess, isLowerGenerationPlan) {
	var children = [];

	const viewGatherOrDisposalAccess =
		viewGatheringPermission || viewDisposalPermission
			? Vgr.enumerations.userAccess.Enabled
			: Vgr.enumerations.userAccess.Hidden;

	if (isLowerGenerationPlan) {
		children = [
			{
				text: function () {
					return labels.LIST;
				},
				states: ["customReport"],
				level: 1
			},
			{
				text: function () {
					return labels.VIEW_KPIS;
				},
				states: ["genericKpiReport"],
				level: 1
			}
		];
	} else {
		children = [
			{
				text: function () {
					return labels.CUSTOM_REPORTS;
				},
				states: ["customReport"],
				level: 1
			},
			{
				text: function () {
					return labels.KEY_PERFORMANCE_INDICATORS;
				},
				states: ["genericKpiReport"],
				level: 1
			},
			{
				text: function () {
					return labels.RELATIVE_KEY_PERFORMANCE_INDICATORS;
				},
				states: ["relativeKpis"],
				level: 1
			},
			{
				text: function () {
					return labels.GOAL_VERSUS_ACCOMPLISHED;
				},
				states: ["goalsReport"],
				level: 1,
				getFeatureAccess: function () {
					return goalAccess;
				}
			},
			{
				text: function () {
					return labels.RESIDUE_TRACEABILITY_REPORT_TITLE;
				},
				states: ["residueTraceabilityReport"],
				clickMeasureKey: Vgr.trackings.residueTraceability.usingMenu,
				level: 1,
				showNewImage: true
			},
			{
				text: function () {
					return labels.INVENTORY_REPORT;
				},
				level: 1,
				children: [
					{
						text: function () {
							return labels.UNIT;
						},
						states: ["generatingManagementInventoryReport"],
						level: 2,
						getFeatureAccess: function () {
							return viewGatherOrDisposalAccess;
						}
					},
					{
						text: function () {
							return labels.CORPORATE;
						},
						states: ["generatingCorporateManagementInventoryReport"],
						level: 2,
						getFeatureAccess: function () {
							return viewGatherOrDisposalAccess;
						}
					}
				]
			},
			{
				text: function () {
					return labels.RESIDUE_MOVIMENTATION_REPORT_TITLE_MENU;
				},
				level: 1,
				children: [
					{
						text: function () {
							return labels.UNIT;
						},
						states: ["residueMovimentationReport"],
						level: 2,
						getFeatureAccess: function () {
							return viewGatherOrDisposalAccess;
						}
					},
					{
						text: function () {
							return labels.CORPORATE;
						},
						states: ["residueMovimentationReportCorporate"],
						level: 2,
						getFeatureAccess: function () {
							return viewGatherOrDisposalAccess;
						}
					}
				]
			},
			{
				text: function () {
					return labels.BALANCE_REPORT_TITLE;
				},
				level: 1,
				children: [
					{
						text: function () {
							return labels.UNIT;
						},
						states: ["cadriBalanceReport"],
						level: 2
					},
					{
						text: function () {
							return labels.CORPORATE;
						},
						states: ["cadriBalanceCorporateReport"],
						level: 2
					}
				]
			}
		];
	}

	return {
		text: function () {
			return labels.REPORTS;
		},
		icon: "insights",
		level: 0,
		fixed: true,
		disabledHover: true,
		children: children
	};
}

function getStatementsMenu(
	labels,
	viewDisposalPermission,
	viewGatheringPermission,
	ibamaRappIntegrationFeatureAccess,
	dnitIntegrationFeatureAccess,
	generateDmrAccess,
	isLowerGenerationPlan
) {
	const integrationAccess =
		viewDisposalPermission ||
		viewGatheringPermission ||
		ibamaRappIntegrationFeatureAccess ||
		dnitIntegrationFeatureAccess ||
		generateDmrAccess;

	const ibamaRappAccess =
		viewDisposalPermission || viewGatheringPermission || ibamaRappIntegrationFeatureAccess
			? Vgr.enumerations.userAccess.Enabled
			: Vgr.enumerations.userAccess.Hidden;

	const viewGatherOrDisposalAccess =
		viewGatheringPermission || viewDisposalPermission
			? Vgr.enumerations.userAccess.Enabled
			: Vgr.enumerations.userAccess.Hidden;

	var children = [];

	if (isLowerGenerationPlan) {
		children = [
			{
				text: function () {
					return labels.DMR;
				},
				states: ["dmrReport"],
				level: 1,
				getFeatureAccess: function () {
					return generateDmrAccess;
				}
			},
			{
				text: function () {
					return labels.RAPP_IBAMA;
				},
				level: 1,
				getFeatureAccess: function () {
					return ibamaRappAccess;
				},
				states: ["ibamaRappList"]
			},
			{
				text: function () {
					return labels.DNIT;
				},
				states: ["dnitReport"],
				level: 1,
				getFeatureAccess: function () {
					return dnitIntegrationFeatureAccess;
				}
			},
			{
				text: function () {
					return labels.CONAMA;
				},
				states: ["conamaInvetoryReport"],
				level: 1,
				getFeatureAccess: function () {
					return viewGatherOrDisposalAccess;
				}
			}
		];
	} else {
		children = [
			{
				text: function () {
					return labels.DMR;
				},
				states: ["dmrReport"],
				level: 1,
				getFeatureAccess: function () {
					return generateDmrAccess;
				}
			},
			{
				text: function () {
					return labels.RAPP_IBAMA;
				},
				level: 1,
				getFeatureAccess: function () {
					return ibamaRappAccess;
				},
				children: [
					{
						text: function () {
							return labels.STATEMENT;
						},
						states: ["ibamaReport"],
						level: 2,
						getFeatureAccess: function () {
							return viewGatherOrDisposalAccess;
						}
					},
					{
						text: function () {
							return labels.INTEGRATION;
						},
						getFeatureAccess: function () {
							return ibamaRappIntegrationFeatureAccess;
						},
						states: ["ibamaRappList"],
						level: 2
					}
				]
			},
			{
				text: function () {
					return labels.DNIT_INTEGRATION;
				},
				states: ["dnitReport"],
				level: 1,
				getFeatureAccess: function () {
					return dnitIntegrationFeatureAccess;
				}
			},
			{
				text: function () {
					return labels.CONAMA313_INVENTORY;
				},
				states: ["conamaInvetoryReport"],
				level: 1,
				getFeatureAccess: function () {
					return viewGatherOrDisposalAccess;
				}
			}
		];
	}

	return {
		text: function () {
			return !isLowerGenerationPlan ? labels.STATEMENTS : labels.INVENTORIES;
		},
		icon: "summarize",
		level: 0,
		getFeatureAccess: function () {
			return integrationAccess ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
		},
		children: children,
		fixed: isLowerGenerationPlan
	};
}

function getRDMMenuChildren(
	labels,
	viewDisposalPermission,
	destinatorModuleAccess,
	stockControlAccess,
	viewStorageAnyPermission
) {
	const storageMenuItem = {
		text: function () {
			return labels.STORE;
		},
		states: ["storageList"],
		icon: "arrow_circle_down",
		level: 1,
		getFeatureAccess: function () {
			return viewStorageAnyPermission && stockControlAccess
				? Vgr.enumerations.userAccess.Enabled
				: Vgr.enumerations.userAccess.Hidden;
		}
	};

	const disposalMenuItem = {
		text: function () {
			return labels.DESTINATE;
		},
		clickMeasureKey: Vgr.trackings.disposal.followupAccess.usingMenu,
		states: ["disposalFollowup"],
		icon: "file_upload",
		level: 1,
		getFeatureAccess: function () {
			return viewDisposalPermission ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
		}
	};

	const mtrFollowupMenuItem = {
		text: function () {
			return labels.FOLLOW_MTR;
		},
		clickMeasureKey: Vgr.trackings.disposal.manifest.followupAccessUsingMenu,
		states: ["mtrFollowUp"],
		icon: "fact_check",
		level: 1,
		getFeatureAccess: function () {
			return viewDisposalPermission ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
		}
	};

	const receiveAsDestinatorMenuItem = {
		icon: "arrow_circle_down",
		level: 1,
		states: ["destinatorDisposalManifestList"],
		text: function () {
			return labels.RECEIVE_MTR;
		},
		getFeatureAccess: function () {
			return destinatorModuleAccess;
		}
	};

	const disposalHistoryMenuItem = {
		text: function () {
			return labels.EXTERNAL_GATHERING_HISTORY;
		},
		states: ["disposalHistory"],
		icon: "list_alt",
		level: 1,
		getFeatureAccess: function () {
			return viewDisposalPermission ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
		}
	};

	return [
		storageMenuItem,
		disposalMenuItem,
		mtrFollowupMenuItem,
		receiveAsDestinatorMenuItem,
		disposalHistoryMenuItem
	];
}
