angular.module("componentes").controller("NumberFieldV2Ctrl", [
	"$scope",
	"clientService",
	function ($scope, clientService) {
		"use strict";

		$scope.$watch("ctrl.field", function (newValue, oldValue) {
			if ($scope.ctrl.changed && newValue != oldValue) {
				$scope.ctrl.changed(newValue);
			}
		});

		this.$onInit = function () {
			const decimals = clientService.getDecimalPlaces();
			$scope.decimals = $scope.ctrl.decimals || $scope.ctrl.decimals === 0 ? $scope.ctrl.decimals : decimals;
		};
	}
]);
