angular.module("componentes").directive("grid", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: true,
			controller: "GridCtrl",
			controllerAs: "ctrl",
			templateUrl: "componentes/angular-material/custom/grid/grid_template.html",
			bindToController: {
				options: "=",
				customClass: "@",
				flexColumnLeft: "@",
				flexRowTopLeft: "@",
				flexRowBottomLeft: "@",
				flexRowTopRight: "@"
			},
			link: function (scope, element, attr) {
				element.on("$destroy", function () {
					$rootScope.$broadcast(Vgr.constants.evtDestroyGrid);
				});
			},
			transclude: {
				topLeftSlot: "?topLeft",
				bottomLeftSlot: "?bottomLeft"
			}
		};
	}
]);
