angular.module("componentes").directive("textcomplete", function () {
	"use strict";

	return {
		scope: true,
		controller: "TextCompleteCtrl",
		controllerAs: "ctrl",
		bindToController: {
			isDisabled: "@",
			isRequired: "=",
			requireMatch: "@?",
			list: "=",
			label: "@",
			searchText: "=?",
			model: "=",
			change: "=?",
			textChange: "=?",
			loadList: "=?",
			name: "@",
			field: "@",
			subfield: "@",
			subfieldLabel: "@?",
			subfieldList: "=?",
			infiniteSubfieldList: "=?",
			error: "=",
			emptyMessage: "@?",
			noFloat: "@",
			maxlength: "@?",
			minlengthValidation: "@?",
			flexValue: "@",
			placeHolder: "@?",
			tooltip: "@?",
			showTooltip: "@?",
			minLength: "=?",
			paginationOptions: "=?",
			loading: "=?",
			itemLayout: "@?",
			iconOptions: "=?",
			rightField: "@?",
			middleField: "@?",
			menuclass: "@",
			inputId: "@",
			ignoreRemoveDuplicated: "="
		},
		require: "^form",
		templateUrl: "componentes/angular-material/custom/fields/textcomplete/textcomplete_template.html",
		link: function (scope, element, attrs, form) {
			var d = performance.now();
			var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
			});

			scope.internalId = "tc-" + uuid;
			if (attrs && !attrs.flexValue) {
				scope.ctrl.flexValue = 10;
			}
			if (attrs && !attrs.minLength) {
				scope.ctrl.minLength = 0;
			}
			scope.form = form;
		}
	};
});
