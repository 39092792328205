angular.module("componentes").directive("gridSimpleV2ActionButtons", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: false,
			templateUrl: "componentes/angular-material/custom/gridSimple/V2/gridSimpleV2ActionButtons_template.html"
		};
	}
]);
