angular.module("componentes").component("inputCustomValidationMessages", {
	controller: "InputCustomValidationMessagesCtrl",
	templateUrl:
		"componentes/angular-material/custom/fields/inputCustomValidationMessages/inputCustomValidationMessages_template.html",
	bindings: {
		error: "<?",
		minValueCustomErrorMessage: "<?",
		maxValueCustomErrorMessage: "<?",
		min: "<?",
		max: "<?"
	}
});
