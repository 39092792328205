angular.module("componentes").directive("reportFilters", function () {
	"use strict";
	return {
		controller: "ReportFiltersCtrl",
		controllerAs: "ctrl",
		replace: true,
		transclude: true,
		templateUrl: "componentes/angular-material/custom/report/reportFilters_template.html",
		bindToController: {
			options: "="
		}
	};
});
