angular.module("componentes").controller("TextFieldCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			if ($scope.ctrl.initCurrentDate) {
				if (!$scope.ctrl.field) {
					$scope.ctrl.field = new Date();
				}
			}

			if ($scope.ctrl.type == "date") {
				if ($scope.ctrl.ignoreDateLimits) {
					$scope.minDate = "";
					$scope.maxDate = "9999-12-31";
				} else {
					setMinDate();
					setMaxDate();
				}
			}
		};

		var typingTimer;
		$scope.$watch("ctrl.field", function (newValue, oldValue) {
			if (newValue == oldValue) {
				return;
			}

			if ($scope.ctrl.mask === "money" || $scope.ctrl.mask === "percentage") {
				$scope.ctrl.field = newValue === "" ? 0 : newValue;
			}

			if ($scope.ctrl.changed) {
				if ($scope.ctrl.typingInterval) {
					if (typingTimer) {
						clearTimeout(typingTimer);
					}
					typingTimer = setTimeout(function () {
						$scope.ctrl.changed(newValue);
					}, $scope.ctrl.typingInterval);
				} else {
					$scope.ctrl.changed(newValue);
				}
			}
		});

		$scope.onFocus = function () {
			if ($scope.ctrl.onFocus) {
				$scope.ctrl.onFocus();
			}
		};

		$scope.onBlur = function () {
			if ($scope.ctrl.onBlur) {
				$scope.ctrl.onBlur();
			}
		};

		$scope.verifyValidity = function (event) {
			if ($scope.ctrl.form && $scope.ctrl.name) {
				var control = $scope.ctrl.form[$scope.ctrl.name];
				var input = (event ? event.target : null) || $("input[name=" + $scope.ctrl.name + "]")[0];

				if (control && input && input.classList) {
					control.$setValidity("cpf", !input.classList.contains("ng-invalid-cpf"));
					control.$setValidity("cnpj", !input.classList.contains("ng-invalid-cnpj"));
				}
			}
		};

		function setMinDate() {
			if ($scope.ctrl.minDate) {
				$scope.minDate = $scope.ctrl.minDate;
			} else {
				$scope.minDate = "1900-01-01";
			}
		}

		function setMaxDate() {
			if ($scope.ctrl.maxDate) {
				$scope.maxDate = $scope.ctrl.maxDate;
			} else {
				$scope.maxDate = "2078-12-31";
			}
		}

		$scope.$watch("ctrl.minDate", function (newValue) {
			if (!$scope.ctrl.ignoreDateLimits) {
				setMinDate();
			}
		});

		$scope.$watch("ctrl.maxDate", function (newValue) {
			if (!$scope.ctrl.ignoreDateLimits) {
				setMaxDate();
			}
		});

		$scope.showPassword = function () {
			$scope.ctrl.type = "text";
		};

		$scope.hidePassword = function () {
			$scope.ctrl.type = "password";
		};
	}
]);
