angular.module("componentes").directive("gridSimple", [
	"$rootScope",
	function ($rootScope) {
		"use strict";
		return {
			scope: true,
			controller: "GridSimpleCtrl",
			controllerAs: "ctrl",
			templateUrl: "componentes/angular-material/custom/gridSimple/gridSimple_template.html",
			bindToController: {
				selectId: "@",
				orderBy: "@",
				list: "=",
				columns: "=",
				title: "@",
				btnAddClick: "=",
				btnRefreshClick: "=",
				btnEditClick: "=",
				defaultActions: "=",
				actionButtons: "=",
				loading: "=?",
				noValuesLabel: "@?",
				hasPermission: "=?",
				selectedRows: "=?",
				showCheckboxColumn: "=?",
				setterRowsIds: "=?",
				disablePropertiesAsObjects: '=?'
			},
			link: function (scope, element, attr) {}
		};
	}
]);
