angular.module("componentes").factory("dialogService", [
	"$mdDialog",
	"$mdMedia",
	function ($mdDialog, $mdMedia) {
		"use strict";

		var dialogService = {};

		var _showDialogFromTemplate = function (ev, templatePath, controller, locals) {
			var useFullScreen = $mdMedia("sm") || $mdMedia("xs");
			return $mdDialog.show({
				templateUrl: templatePath,
				parent: angular.element(document.body),
				targetEvent: ev,
				controller: controller,
				locals: { scopeData: locals },
				clickOutsideToClose: true,
				fullscreen: useFullScreen
			});
		};

		var _showDialogFromTemplateWithScope = function (
			ev,
			templatePath,
			controller,
			scope,
			parent,
			blockClickOutsideToClose,
			onRemovingFunc,
			locals
		) {
			var clickOutsideToClose = !blockClickOutsideToClose ? true : false;
			parent = parent ? angular.element(parent) : angular.element(document.body);
			var useFullScreen = $mdMedia("sm") || $mdMedia("xs");
			var config = {
				templateUrl: templatePath,
				parent: parent,
				targetEvent: ev,
				controller: controller,
				preserveScope: scope ? true : false,
				clickOutsideToClose: clickOutsideToClose,
				fullscreen: useFullScreen,
				onRemoving: onRemovingFunc
			};

			if (scope) {
				config.scope = scope;
			}
			if (locals) {
				config.locals = locals;
				config.bindToController = true;
			}

			return $mdDialog.show(config);
		};

		var _showDialogFromTemplateV2 = function (templatePath, controller, parent, locals, ignoreClickModalBackdropClose) {
			parent = parent ? angular.element(parent) : angular.element(document.body);
			var fullScreen = $mdMedia("sm") || $mdMedia("xs");

			var config = {
				templateUrl: templatePath,
				controller: controller,
				parent: parent,
				preserveScope: false,
				clickOutsideToClose: !ignoreClickModalBackdropClose,
				fullscreen: fullScreen
			};

			if (locals) {
				config.locals = locals;
				config.bindToController = true;
			}

			return $mdDialog.show(config);
		};

		var _hide = function hide(promise) {
			$mdDialog.cancel(promise);
		};

		var _confirm = function (data) {
			$mdDialog.hide(data);
		};

		var _cancel = function (data) {
			$mdDialog.cancel(data);
		};

		dialogService.showDialogFromTemplate = _showDialogFromTemplate;
		dialogService.showDialogFromTemplateWithScope = _showDialogFromTemplateWithScope;
		dialogService.hide = _hide;

		dialogService.showDialogFromTemplateV2 = _showDialogFromTemplateV2;
		dialogService.confirm = _confirm;
		dialogService.cancel = _cancel;

		return dialogService;
	}
]);
