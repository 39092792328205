angular.module("componentes").directive("chipsSmall", function () {
	"use strict";

	return {
		scope: true,
		controller: "ChipsSmallCtrl",
		controllerAs: "ctrl",
		templateUrl: "componentes/angular-material/custom/chips-small/chipsSmall_template.html",
		bindToController: {
			selected: "=",
			flexValue: "@",
			valueField: "@",
			list: "=",
			label: "@",
			field: "@",
			name: "@",
			placeholder: "@",
			match: "=",
			readonly: "=",
			select: "=?"
		}
	};
});

Cmp.constructors.ChipsOptions = function ChipsOptions() {
	this.showTagChips = true;
	this.selectedTags = [];
	this.tagList = [];

	this.setTagList = function setTagList(tagList) {
		var self = this;

		Cmp.util.initListFromArrayOrService(tagList, function (list) {
			self.tagList = list;
		});
	};
};
