var Cmp = Cmp || {};
Cmp = {
	constructors: {},
	events: {},
	constants: {},
	resources: {}
};

Cmp.util = {
	uuid: function () {
		"use strict";

		var d = performance.now();
		var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	},

	getService: function getService(seviceName) {
		return angular.element(document.body).injector().get(seviceName);
	},

	initListFromArrayOrService: function initListFromArrayOrService(arrayOrService, setList) {
		if (Array.isArray(arrayOrService)) {
			setList(arrayOrService);
		} else if (arrayOrService instanceof Function) {
			Cmp.util.getService("httpService").getListFromService(arrayOrService).then(setList);
		} else {
			setList([]);
		}
	}
};
