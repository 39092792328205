angular.module("componentes").controller("ChipsSmallCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.querySearch = function querySearch(query) {
			if ($scope.list) {
				var availableItems = $scope.getAvailableItems();
				var results = query ? availableItems.filter($scope.createFilterFor(query)) : availableItems;
				return results;
			}
		};

		$scope.getAvailableItems = function () {
			if ($scope.list) {
				return $scope.list.filter(function (item) {
					return !item.isSelected();
				});
			}
		};

		$scope.createFilterFor = function createFilterFor(query) {
			return function filterFn(item_) {
				$scope.item = item_;
				return Vgr.util.isStringIncluded($scope.item._lowername, query);
			};
		};

		$scope.isSelected = function (item) {
			if (!item) {
				item = this;
			}

			if (
				Array.isArray($scope.ctrl.selected) &&
				$scope.ctrl.selected.find(function (selectedItem) {
					return selectedItem[$scope.ctrl.valueField] == item[$scope.ctrl.valueField];
				})
			) {
				return true;
			}
			return false;
		};

		$scope.transformChip = function transformChip(chip) {
			if (angular.isObject(chip)) {
				return chip;
			}

			var chipObj = {};
			chipObj[$scope.ctrl.field] = chip;
			return chipObj;
		};

		$scope.isInvalid = function () {
			return $scope.ctrl.selected && $scope.ctrl.selected.length === 0;
		};

		$scope.hasClass = function (cls) {
			var mdChips = "";

			mdChips = $("md-chips[name=" + $scope.ctrl.name + "] input");

			return mdChips && mdChips.hasClass(cls);
		};

		$scope.getErrorMessage = function (error) {
			if (error === "ng-invalid-required") {
				return $rootScope.labels.FIELD_VALIDATION_MANDATORY_MESSAGE;
			} else if (error == "ng-invalid") {
				return $rootScope.labels.FIELD_VALIDATION_INVALID_MESSAGE;
			}
		};

		var checkExist = setInterval(function () {
			if ($("md-chips-wrap").length) {
				$("md-chips-wrap").attr("layout", "row");
				clearInterval(checkExist);
			}
		}, 100); // check every 100ms

		$scope.$watch("ctrl.list", function () {
			if ($scope.ctrl.list && Array.isArray($scope.ctrl.list)) {
				$scope.list = $scope.ctrl.list.map(function (item) {
					item._lowername = item.Name.toString().toLowerCase();
					item.isSelected = $scope.isSelected;
					return item;
				});
			}
		});
	}
]);
