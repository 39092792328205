angular.module("componentes").controller("InputCustomValidationMessagesCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.decimals = clientService.getDecimalPlaces();

		function loadLabels() {
			updateMinQuantityErrorMessage($scope.$ctrl.min);
			updateMaxQuantityErrorMessage($scope.$ctrl.max);
		}

		this.$onInit = function () {
			loadLabels();
		};

		this.$onChanges = function (obj) {
			if (obj.min && obj.min.currentValue != obj.min.previousValue) {
				updateMinQuantityErrorMessage(obj.min.currentValue);
			}

			if (
				obj.minValueCustomErrorMessage &&
				obj.minValueCustomErrorMessage.currentValue != obj.minValueCustomErrorMessage.previousValue
			) {
				updateMinQuantityErrorMessage($scope.$ctrl.min);
			}

			if (
				obj.maxValueCustomErrorMessage &&
				obj.maxValueCustomErrorMessage.currentValue != obj.maxValueCustomErrorMessage.previousValue
			) {
				updateMaxQuantityErrorMessage($scope.$ctrl.max);
			}

			if (obj.max && obj.max.currentValue != obj.max.previousValue) {
				updateMaxQuantityErrorMessage(obj.max.currentValue);
			}
		};

		function updateMinQuantityErrorMessage(quantity) {
			var formattedQuantity = Vgr.util.formatUnitDecimalPlaces(quantity, $scope.decimals);
			if ($scope.$ctrl.minValueCustomErrorMessage) {
				$scope.minValueErrorMessage = $scope.$ctrl.minValueCustomErrorMessage.replace("{0}", formattedQuantity);
			} else {
				$scope.minValueErrorMessage = $scope.labels.VALUE_BELOW_MIN_ALLOWED + formattedQuantity;
			}
		}

		function updateMaxQuantityErrorMessage(quantity) {
			var formattedQuantity = Vgr.util.formatUnitDecimalPlaces(quantity, $scope.decimals);
			if ($scope.$ctrl.maxValueCustomErrorMessage) {
				$scope.maxValueErrorMessage = $scope.$ctrl.maxValueCustomErrorMessage.replace("{0}", formattedQuantity);
			} else {
				$scope.maxValueErrorMessage = $scope.labels.VALUE_BIGGER_MAX_ALLOWED + formattedQuantity;
			}
		}
	}
]);
