angular.module("componentes").directive("reportContainerV2", function () {
	"use strict";
	return {
		controller: "ReportContainerV2Ctrl",
		controllerAs: "ctrl",
		transclude: {
			descriptionSlot: "?description",
			filterSlot: "?filter"
		},
		templateUrl: "componentes/angular-material/custom/report/reportContainerV2_template.html",
		bindToController: {
			options: "="
		}
	};
});

Cmp.constructors.ReportOptionsV2 = function ReportOptionsV2(title, generateReport, filter) {
	"use strict";
	var $rootScope = Cmp.util.getService("$rootScope");

	this.title = title;
	this.filter = filter;
	this.reload = function () {
		generateReportInFormat("PDF");
	};
	this.generateExcel = function () {
		generateReportInFormat("EXCEL");
	};
	this.generateWord = function () {
		generateReportInFormat("WORD");
	};

	var self = this;
	function generateReportInFormat(Format) {
		self.filter.Format = Format;
		self.promise = generateReport(self.filter);
	}
};
