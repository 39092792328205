angular.module("componentes").directive("reportContainer", function () {
	"use strict";
	return {
		controller: "ReportContainerCtrl",
		controllerAs: "ctrl",
		transclude: true,
		templateUrl: "componentes/angular-material/custom/report/reportContainer_template.html",
		bindToController: {
			options: "="
		}
	};
});

Cmp.constructors.ReportOptions = function ReportOptions(title, generateReport, filter, removeAlertType) {
	"use strict";

	this.title = title;
	this.filter = filter;
	this.removeAlertType = removeAlertType;
	this.reload = function () {
		generateReportInFormat("PDF");
	};
	this.generateExcel = function () {
		generateReportInFormat("EXCEL");
	};
	this.generateWord = function () {
		generateReportInFormat("WORD");
	};
	this.generateCsv = function () {
		generateReportInFormat("CSV");
	};

	var self = this;
	function generateReportInFormat(format) {
		self.filter.format = format;
		self.promise = generateReport(self.filter);
	}
};
