angular.module("componentes").controller("GroupSelectFieldV2Ctrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		var keyProperty;
		var categoryProperty;

		var defaultCategoryLabel;

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.placeholder = $scope.$ctrl.label ? $scope.$ctrl.label : $scope.$ctrl.placeholder;

			keyProperty = $scope.$ctrl.keyProperty ? $scope.$ctrl.keyProperty : "id";
			$scope.displayProperty = $scope.$ctrl.displayProperty ? $scope.$ctrl.displayProperty : "description";
			categoryProperty = $scope.$ctrl.categoryProperty ? $scope.$ctrl.categoryProperty : "category";

			if (!$scope.$ctrl.defaultCategoryLabel) {
				defaultCategoryLabel = "";
			}

			setItemsAndCategories($scope.$ctrl.items);

			setValues($scope.$ctrl.setter, false);
		};

		function setItemsAndCategories(items) {
			$scope.listByCategories = {};
			$scope.categories = Array.from(
				new Set(
					items.map(function (item) {
						return item[categoryProperty];
					})
				)
			);
			for (var i = 0; i < $scope.categories.length; ++i) {
				if (!$scope.categories[i]) {
					$scope.categories[i] = defaultCategoryLabel;
				}
			}
			$scope.categories = $scope.categories.sort();

			$scope.categoriesMap = items.map(function (val, index) {
				return { category: items[index].category, value: items[index] };
			});

			for (var i = 0; i < $scope.categoriesMap.length; ++i) {
				var categoryMap = $scope.categoriesMap[i];

				if (!$scope.listByCategories[categoryMap.category]) {
					$scope.listByCategories[categoryMap.category] = [];
				}
				$scope.listByCategories[categoryMap.category].push(categoryMap.value);
			}
		}

		$scope.onChange = function (newValue, oldValue, opt) {
			callback();
		};

		this.$onChanges = function (obj) {
			if (obj.setter && !obj.setter.isFirstChange()) {
				if (obj.setter.currentValue != null) {
					setValues(obj.setter.currentValue);
				}
			}
			if (obj.clear) {
				if (obj.clear.currentValue) {
					setValues();
				}
			}
		};

		function setValues(value, canCallbackWhenNull) {
			if (value) {
				var itemsFiltered = $scope.$ctrl.items.filter(function (item) {
					return item[keyProperty] === value;
				});

				$scope.selectedItem = itemsFiltered.length > 0 ? itemsFiltered[0] : null;

				callback();
			} else {
				$scope.selectedItem = null;

				if (canCallbackWhenNull) {
					callback();
				}
			}
		}

		function callback() {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback($scope.selectedItem);
			}
		}

		$scope.isOptionDisabled = function (item) {
			if ($scope.selectedItem == item) {
				return false;
			}

			if (!item.isOptionEnabled) {
				return false;
			}

			return !item.isOptionEnabled();
		};
	}
]);
