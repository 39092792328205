angular.module("componentes").directive("vgrContainer", function () {
	"use strict";
	return {
		scope: true,
		controller: "VgrContainerCtrl",
		controllerAs: "ctrl",
		transclude: {
			alertContentSlot: "?alertContent",
			headerContentSlot: "?headerContent",
			contentSlot: "content",
			footerContentSlot: "?footerContent",
			topLeftSlot: "?topLeft",
			bottomLeftSlot: "?bottomLeft",
			bottomRightSlot: "?bottomRight"
		},
		//replace: true,
		templateUrl: "componentes/angular-material/custom/vgrContainer/vgrContainer_template.html",
		bindToController: {
			title: "@",
			saveOptions: "=",
			toolbarOptions: "=",
			isLoading: "=?",
			totalItems: "=?",
			onScrollEnd: "=?",
			new: "=?",
			flexColumnLeft: "@?",
			flexRowTopLeft: "@?",
			flexRowBottomLeft: "@?",
			flexRowTopRight: "@?",
			flexRowBottomRight: "@?"
		}
	};
});
