angular.module("componentes").controller("ContainerMenuButtonCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.isExecuting = false;
		$scope.isMenuDisabled = false;
		$scope.open = false;

		$scope.onClick = function ($event, menuItem) {
			if ($event) {
				$event.stopPropagation();
			}

			// wait for close menu transition time to finish - 200ms
			Vgr.util.sleep(300).then(function () {
				executeMenuItemClick(menuItem);
			});
		};

		function executeMenuItemClick(menuItem) {
			if (menuItem) {
				if (menuItem.method) {
					$scope.isExecuting = true;
					var promise = menuItem.method(menuItem.parameters);
					if (promise) {
						if (menuItem.parameters && menuItem.parameters.event == undefined) {
							$scope.isExecuting = false;
						}
						promise.then(
							function () {
								$scope.isExecuting = false;
							},
							function () {
								$scope.isExecuting = false;
							}
						);
					}
				} else if (menuItem.onClick) {
					menuItem.onClick();
				} else if (menuItem.onClickPromise) {
					$scope.isExecuting = true;
					menuItem.onClickPromise().then(
						function () {
							$scope.isExecuting = false;
						},
						function () {
							$scope.isExecuting = false;
						}
					);
				}
			}
		}

		$scope.$on("$mdMenuClose", function (ev, element) {
			$scope.open = false;
		});

		$scope.$on("$mdMenuOpen", function (ev, element) {
			$scope.open = true;
		});

		$scope.isDisabled = function () {
			return $scope.isExecuting || ($scope.ctrl.isDisabled && $scope.ctrl.isDisabled());
		};

		$scope.onClickMenu = function ($event) {
			if ($event) {
				$event.stopPropagation();
			}
		};
	}
]);
