angular.module("componentes").directive("textFieldV2", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			scope: true,
			controller: "TextFieldV2Ctrl",
			controllerAs: "ctrl",
			replace: true,
			templateUrl: "componentes/angular-material/custom/fields/textField/textFieldV2_template.html",
			bindToController: {
				label: "@",
				name: "@",
				isDisabled: "=",
				isRequired: "=",
				type: "@",
				field: "=",
				mask: "@?",
				maxlength: "@?",
				error: "=?",
				changed: "=?",
				decimalPlaces: "=?",
				forceDecimalPlaces: "=?",
				onBlur: "&?",
				customClass: "@",
				initCurrentDate: "@",
				autofocus: "@?",
				showEye: "=?",
				typingInterval: "@?"
			},
			require: "^?form",
			link: function (scope, element, attrs, form) {
				scope.ctrl.form = form;
			}
		};
	}
]);
