angular.module("componentes").directive("whenscrollends", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				if (!attrs.whenscrollends || !scope.ctrl.paginationOptions) return;

				var input = element.find("input");
				var findInput = function () {
					setTimeout(function () {
						input = element.find("input");
						if (input.length === 0) {
							findInput();
						} else {
							input.addClass("autoCompleteArrow");
						}
					}, 100);
				};

				var mdRepeatContainer = element.find("md-virtual-repeat-container");
				var container = element[0].querySelector(".md-virtual-repeat-scroller");

				var previousScrollPos = 1000000;
				if (scope.ctrl.paginationOptions.id) {
					$timeout(function () {
						angular.element(input).focus();
					}, 4000);
				}
				angular.element(container).on("scroll", function (e) {
					if (!scope.ctrl.paginationOptions.allDataLoaded) {
						var scrollableHeight = e.target.scrollHeight;
						var hidden = scrollableHeight - mdRepeatContainer[0].scrollHeight;

						var scrollPos = hidden - container.scrollTop;

						if (previousScrollPos > 100 && scrollPos <= 100) {
							previousScrollPos = scrollPos;
							scope.$apply(attrs.whenscrollends).then(function () {
								angular.element(input).focus();
							});
						} else if (scrollPos > 100) {
							previousScrollPos = 1000000;
						}
					}
				});
				if (input.length === 0) findInput();
			}
		};
	}
]);
