angular.module("componentes").controller("TextFieldV2Ctrl", [
	"$scope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			if ($scope.ctrl.initCurrentDate) {
				if (!$scope.ctrl.field) {
					$scope.ctrl.field = new Date();
				}
			}

			if ($scope.ctrl.type == "date") {
				$scope.minDate = "1900-01-01";
				$scope.maxDate = "2078-12-31";
			}
		};

		var typingTimer;
		$scope.$watch("ctrl.field", function (newValue, oldValue) {
			if (newValue == oldValue) {
				return;
			}

			if ($scope.ctrl.mask === "money" || $scope.ctrl.mask === "percentage") {
				$scope.ctrl.field = newValue === "" ? 0 : newValue;
			}

			if ($scope.ctrl.changed) {
				if ($scope.ctrl.typingInterval) {
					if (typingTimer) {
						clearTimeout(typingTimer);
					}
					typingTimer = setTimeout(function () {
						$scope.ctrl.changed(newValue);
					}, $scope.ctrl.typingInterval);
				} else {
					$scope.ctrl.changed(newValue);
				}
			}
		});

		$scope.verifyValidity = function (event) {
			if ($scope.ctrl.form && $scope.ctrl.name) {
				var control = $scope.ctrl.form[$scope.ctrl.name];
				var input = (event ? event.target : null) || $("input[name=" + $scope.ctrl.name + "]")[0];

				if (control && input && input.classList) {
					control.$setValidity("cpf", !input.classList.contains("ng-invalid-cpf"));
					control.$setValidity("cnpj", !input.classList.contains("ng-invalid-cnpj"));
				}
			}
		};

		$scope.showPassword = function () {
			$scope.ctrl.type = "text";
		};

		$scope.hidePassword = function () {
			$scope.ctrl.type = "password";
		};
	}
]);
