angular.module("componentes").controller("GroupSelectFieldCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.placeholder = $scope.ctrl.label ? $scope.ctrl.label : $scope.ctrl.placeholder;
			$scope.getList();
		};

		$scope.$watch("ctrl.model", function (newValue) {
			if ($scope.ctrl.changed) {
				$scope.ctrl.changed();
			}
		});

		$scope.$watch("ctrl.list", function (newValue, oldValue) {
			if (!newValue) {
				$scope.list = [];
			} else {
				$scope.list = newValue;
				setCategories();
			}
		});

		function setCategories() {
			if (!$scope.ctrl.categoryPropertyName) {
				$scope.ctrl.categoryPropertyName = "category";
			}
			$scope.listByCategories = {};
			$scope.categories = Array.from(
				new Set(
					$scope.list.map(function (l) {
						return l[$scope.ctrl.categoryPropertyName];
					})
				)
			);
			for (var i = 0; i < $scope.categories.length; ++i) {
				if (!$scope.categories[i]) {
					$scope.categories[i] = $scope.ctrl.defaultCategoryLabel;
				}
			}

			$scope.categories = $scope.categories.sort();
			$scope.categoriesMap = $scope.list.map(function (val, index) {
				return { category: $scope.list[index].category, value: $scope.list[index] };
			});

			for (var i = 0; i < $scope.categoriesMap.length; ++i) {
				var categoryMap = $scope.categoriesMap[i];

				if (!$scope.listByCategories[categoryMap.category]) {
					$scope.listByCategories[categoryMap.category] = [];
				}
				$scope.listByCategories[categoryMap.category].push(categoryMap.value);
			}
		}

		$scope.getList = function () {
			if (!$scope.ctrl.loadList) {
				$scope.list = $scope.ctrl.list;
			} else {
				$scope.ctrl.loadList($scope.ctrl.loadListItem).then(function (list) {
					$scope.list = list;
				});
			}
		};
	}
]);
