(function () {
	angular.module("common", ["ui-notification"]);
})();

var Common = {
	constants: {
		vgrUrl: "http://localhost:5000",

		// socket client constants
		socketServer: "http://localhost:5000",
		env: "dev"
	}
};
