angular.module("componentes").controller("TextCompleteCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$filter",
	"$timeout",
	function ($scope, $rootScope, $q, $filter, $timeout) {
		"use strict";

		$scope.dirty = false;
		var onClearFilter;

		this.$onInit = function () {
			var self = this;
			self.querySearch = querySearch;
			self.simulateQuery = true;

			if ($scope.ctrl.emptyMessage) {
				$scope.emptyMessage = $scope.ctrl.emptyMessage;
			} else {
				$scope.emptyMessage = $rootScope.labels.NO_REGISTER_FOUND;
			}

			if (!$scope.ctrl.list) {
				$scope.ctrl.list = [];
			}

			onClearFilter = $rootScope.$on("CLEAR_FILTER", function () {
				$scope.ctrl.model = null;
				$scope.ctrl.searchText = "";
			});
		};

		this.$onDestroy = function () {
			// disable the listener
			onClearFilter();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function removeDuplicated(list) {
			if (!list) {
				return;
			}

			var keys = ["ID", "Id", "id"];
			var returnList = [];

			list.forEach(function (item) {
				var isUnique = keys.some(function (key) {
					if (item && item[key]) {
						var searchCriteria = {};
						searchCriteria[key] = item[key];
						return !$filter("filter")($scope.ctrl.list, searchCriteria, true)[0];
					}
					return false;
				});

				if (isUnique) {
					returnList.push(item);
				}
			});

			return returnList;
		}

		function load(deferred) {
			$scope.ctrl.paginationOptions.query = $scope.ctrl.searchText;
			$scope.ctrl.loadList($scope.ctrl.paginationOptions).then(
				function (list) {
					if (!$scope.ctrl.ignoreRemoveDuplicated) {
						var uniqueList = removeDuplicated(list);
						$scope.ctrl.list.push.apply($scope.ctrl.list, uniqueList);
						if ($scope.ctrl.paginationOptions.id) {
							$scope.ctrl.model = $scope.ctrl.list[0];
							delete $scope.ctrl.paginationOptions.id;
						}
					} else {
						$scope.ctrl.list = list;
					}
					deferred.resolve($scope.ctrl.list);
				},
				function (error) {
					//do nothing
				}
			);
		}

		$scope.getMinLength = function () {
			return $scope.ctrl.minLength ? $scope.ctrl.minLength : 0;
		};

		$scope.loadList = function (query) {
			var deferred = $q.defer();
			if ($scope.useTimeout) {
				$timeout(function () {
					load(deferred);
				}, 500);
			} else {
				load(deferred);
			}
			return deferred.promise;
		};

		$scope.textChange = function (text) {
			$scope.useTimeout = true;
			if ($scope.ctrl.paginationOptions) {
				$scope.ctrl.paginationOptions.query = text;
				$scope.ctrl.list = [];
				$scope.ctrl.paginationOptions.pageNumber = 1;
			}
			if ($scope.ctrl.textChange) {
				$scope.ctrl.textChange(text);
			}
		};

		function querySearch(query) {
			if ($scope.ctrl.paginationOptions) {
				return $scope.loadList(query);
			}

			var results = query ? $scope.ctrl.list.filter(createFilterFor(query)) : $scope.ctrl.list,
				deferred;
			deferred = $q.defer();
			if (results) {
				deferred.resolve(results);
			} else {
				deferred.reject();
			}
			return deferred.promise;
		}

		$scope.onScrollEnds = function () {
			$scope.ctrl.paginationOptions.pageNumber = $scope.ctrl.paginationOptions.pageNumber + 1;
			$scope.useTimeout = false;
			var deferred = $q.defer();
			deferred.resolve();
			return deferred.promise;
		};

		function createFilterFor(query) {
			return function filterFn(item) {
				var itemField = $scope.ctrl.field ? item[$scope.ctrl.field] : item;
				itemField = itemField instanceof String ? itemField : itemField + "";

				return Vgr.util.isStringIncluded(itemField, query);
			};
		}

		$scope.onSelectedItem = function (item) {
			if ($scope.ctrl.change) {
				$scope.ctrl.change(item);
			}
		};

		$scope.getErrorMessage = function (error) {
			if (error === "ng-invalid-required") {
				return $rootScope.labels.FIELD_VALIDATION_MANDATORY_MESSAGE;
			} else if (error == "ng-invalid") {
				return $rootScope.labels.FIELD_VALIDATION_INVALID_MESSAGE;
			}
		};

		$scope.hasClass = function (cls) {
			var input = $("textcomplete[name=" + $scope.ctrl.name + "] input");
			return input.hasClass(cls);
		};

		$scope.onClickLabelFields = function ($event) {
			$event.stopPropagation();
			$("#" + $scope.internalId + " input").focus();
		};
	}
]);
