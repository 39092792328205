Cmp.constructors.GoogleMapOptions = function GoogleMapOptions(centerCoordinates, zoom) {
	"use strict";

	var $rootScope = angular.element(document.body).injector().get("$rootScope");

	var redDot = "img/Red_Pin.png";
	var greenDot = "img/Green_Pin.png";
	var grayDot = "img/Gray_Pin.png";
	var blueDot = "img/Blue_Pin.png";
	try {
		this.infoWindow = new google.maps.InfoWindow();
	} catch (error) {
		console.log(error);
	}
	this.markers = [];
	this.clusterMarkers = [];

	this.centerCoordinates = centerCoordinates;
	this.zoom = zoom;

	this.setMarkerClustered = function setMarkerClustered(markers) {
		var markerCluster = new MarkerClusterer(this.map, markers, {
			imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			zoomOnClick: true,
			gridSize: 60
		});
		this.clusterMarkers.push(markerCluster);
		return markerCluster;
	};

	this.setMarker = function setMarker(
		position,
		title,
		content,
		color,
		showInfoOnClick,
		centerOnClick,
		centerOnCreate,
		showOnTop
	) {
		content = content ? content : "";

		var index = 1;
		if (showOnTop) {
			index = 100;
		}

		var icon = redDot;
		if (color == "green") {
			icon = greenDot;
		}
		if (color == "gray") {
			icon = grayDot;
		}
		if (color == "blue") {
			icon = blueDot;
		}

		var markerOptions = {
			position: position,
			map: this.map,
			title: title,
			icon: icon,
			zIndex: index
		};

		var marker = new google.maps.Marker(markerOptions);

		marker.setZIndex(index);
		this.markers.push(marker);

		var _infoWindow = this.infoWindow;
		google.maps.event.addListener(marker, "click", function () {
			if (centerOnClick) {
				this.map.panTo(marker.getPosition());
			}
			if (showInfoOnClick) {
				_infoWindow.setContent("<h3>" + title + "</h3>" + content);
				_infoWindow.open(this.map, marker);
			}
		});

		if (centerOnCreate && this.map) {
			this.map.panTo(marker.getPosition());
		}

		return marker;
	};

	this.setMapOnAll = function setMapOnAll(map) {
		for (var i = 0; i < this.markers.length; i++) {
			this.markers[i].setMap(map);
		}
	};

	this.route = function Route(origin, destination, waypoints) {
		var directionsService = new google.maps.DirectionsService();
		var directionsRenderer = new google.maps.DirectionsRenderer();
		directionsRenderer.setMap(this.map);

		var routeRequest = {
			origin: { query: origin },
			destination: { query: destination },
			travelMode: "DRIVING"
		};

		if (waypoints && waypoints.length > 0) {
			var routeWaypoints = [];
			waypoints.forEach(function (waypoint) {
				routeWaypoints.push({ location: waypoint });
			});
			routeRequest.waypoints = routeWaypoints;
		}

		directionsService.route(routeRequest, function (response, status) {
			if (status === "OK") {
				directionsRenderer.setDirections(response);
			}
		});
	};

	this.clearMarkers = function clearMarkers() {
		this.setMapOnAll(null);
	};

	this.showMarkers = function showMarkers() {
		this.setMapOnAll(this.map);
	};

	this.deleteMarkers = function deleteMarkers() {
		this.clearMarkers();
		this.markers = [];
		this.clusterMarkers = [];
	};

	this.init = function init(mapId, options) {
		var center = this.centerCoordinates;
		if (!center || (!center.lat && !center.lng)) {
			center = {
				lat: -20,
				lng: -44
			};
		}

		var initOptions = {
			zoom: zoom,
			center: center,
			//minZoom: 3,
			maxZoom: 10,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false
		};

		for (var key in options) {
			if (options.hasOwnProperty(key)) {
				initOptions[key] = options[key];
			}
		}

		this.map = new google.maps.Map(document.getElementById(mapId), initOptions);

		var self = this;
		google.maps.event.addListener(this.map, "tilesloaded", function (evt) {
			if (self.onRenderFinished) {
				self.onRenderFinished();
			}
		});

		google.maps.event.addListener(this.map, "bounds_changed", function (evt) {
			if (self.onBoundsChanged) {
				self.onBoundsChanged();
			}
		});

		google.maps.event.addListener(this.map, "zoom_changed", function (evt) {
			if (self.onZoomChanged) {
				self.onZoomChanged();
			}

			if (self.onDragEndOrZoomChanged) {
				self.onDragEndOrZoomChanged();
			}
		});

		google.maps.event.addListener(this.map, "dragend", function (evt) {
			if (self.onDragEnd) {
				self.onDragEnd();
			}

			if (self.onDragEndOrZoomChanged) {
				self.onDragEndOrZoomChanged();
			}
		});

		if (this.onInit) {
			this.onInit();
		}
	};
};

Cmp.constructors.MapPoint = function MapPoint(lat, lng) {
	this.lat = lat || 0;
	this.lng = lng || 0;
};
