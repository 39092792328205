angular.module("componentes").controller("ReportContainerCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$scope.isLoadingPdf = true;
			$scope.options = {};

			$scope.saveOptions = {
				removeButtonBar: true,
				isReportContainer: true
			};

			$scope.filterReport = true;
			$scope.toolbarOptions = {
				buttons: $scope.ctrl.options.toolbarButtons
					? $scope.ctrl.options.toolbarButtons
					: [$rootScope.labels.FILTER, $rootScope.labels.EXCEL, $rootScope.labels.WORD],
				events: {
					onToggleFilter: function () {
						$scope.filterReport = !$scope.filterReport;
					},
					onClickExcel: function () {
						if ($scope.options.generateExcel) {
							$scope.options.generateExcel();
						}
					},
					onClickWord: function () {
						if ($scope.options.generateWord) {
							$scope.options.generateWord();
						}
					},
					onClickCsv: function () {
						if ($scope.options.generateCsv) {
							$scope.options.generateCsv();
						}
					},
				}
			};

			$rootScope.contentNavBar = false;
		};

		$scope.$watch("ctrl.options", function (newVal) {
			if (newVal) {
				$scope.options = newVal;
				$scope.options.filter = newVal.filter || { tagList: [] };

				$scope.options.onFilterClick = function (useTagId) {
					this.filter.joinTags(useTagId);
					if ($scope.options.reload) {
						$scope.isLoadingPdf = true;
						$scope.options.reload();
						$scope.filterReport = false;
						$scope.toolbarOptions.activeButton = "";
					}
				};

				$scope.options.filter.joinTags = function joinTags(useTagId) {
					if (this.showTagChips && Array.isArray(this.selectedTags)) {
						if (useTagId) {
							this.tags = this.selectedTags
								.map(function (item) {
									return item.ID;
								})
								.join();
						} else {
							this.tags = this.selectedTags
								.map(function (item) {
									return item.Name;
								})
								.join();
						}
					} else {
						this.tags = null;
					}
				};

				if ($scope.options.promise) {
					onLoadReport($scope.options.promise);
				}
			}
		});

		function onLoadReport(promise) {
			if (promise) {
				$scope.toolbarOptions.disabled = true;
				promise.then(
					function (response) {
						var data = response.data.d ? JSON.parse(response.data.d) : response.data;
						if (data && !data.success) {
							if (data.errorCode) {
								$scope.filterReport = true;
								$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[data.errorCode]);
							} else {
								$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_ERROR);
							}
						}
						$scope.toolbarOptions.disabled = false;
						$scope.isLoadingPdf = false;
						$scope.options.buttonLabel = $scope.labels.FILTER;
					},
					function (response) {
						$scope.isLoadingPdf = false;
						$scope.toolbarOptions.disabled = false;
						if (response.status != Vgr.constants.httpStatus.Forbidden) {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
						}
					}
				);
			} else {
				$scope.toolbarOptions.disabled = false;
				$scope.isLoadingPdf = false;
				$scope.options.buttonLabel = $scope.labels.VIEW;
			}
		}

		$scope.$watch("ctrl.options.promise", function (newVal) {
			onLoadReport(newVal);
		});
	}
]);
